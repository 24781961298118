import Format from "./format";
import Options from "./options";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import Search from "./search";
import styles from "./variables.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const Variables = ({ messageId, onOptionSelected: callback, action }) => {
	const selectedApp = useSelector((state) => state.system.selectedApp)
	const [search, onSearchChange] = useState('')
	const [isShowingDropdown, setIsShowingDropdown] = useState(false)
	const { t } = useTranslation(['app'])
	const toggleButtonRef = useRef(null)
	const dropdownRef = useRef(null)
	const dispatch = useDispatch()

	const handleClickOutside = useCallback(
		(event) => {
			if (
				dropdownRef &&
				toggleButtonRef &&
				!toggleButtonRef.current.contains(event.target) &&
				!dropdownRef.current.contains(event.target)
			) {
				setIsShowingDropdown(false)
			}
		},
		[dropdownRef, toggleButtonRef]
	)

	useEffect(() => {
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [handleClickOutside])

	const onToggleDropdown = useCallback(() => {
		setIsShowingDropdown(!isShowingDropdown)
		if (!isShowingDropdown === true) {
			document.addEventListener('mousedown', handleClickOutside)
		} else {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [isShowingDropdown, handleClickOutside])

	const variables = useSelector((state) => state.messageConfig.variables)

	const onOptionSelected = useCallback(
		(key, field) => {
			const regKey = key.replace('-', '_')
			var txtToAdd = `@${regKey}_${field}`.toLowerCase()
			callback(txtToAdd)
			setIsShowingDropdown(false)
		},
		[callback]
	)

	return (
		<div className={styles.container}>
			<div>
				<div ref={toggleButtonRef} onClick={onToggleDropdown} className='variable-button'>
					{t('VARIABLES')}
				</div>
			</div>
			<div ref={dropdownRef} className='dropdown variable-dropdown'>
				<div
					className={`dropdown-menu ${action ? "variable-dropdown-action" : "variable-dropdown-menu"}`}
					style={{ display: isShowingDropdown ? 'block' : 'none' }}
				>
					<Search value={search} onChange={(e) => onSearchChange(e.target.value)} />
					<Options
						selectedApp={selectedApp}
						messageId={messageId}
						search={search}
						onOptionSelected={onOptionSelected}
						variables={variables}
						dispatch={dispatch}
					/>
					<Format />
				</div>
			</div>
		</div>
	)
}

export default memo(Variables)
