import DupSvg from "../../../../assets/svg/Duplicate";
import HandleSource from "../system/handle/handleSource";
import PlusSvg from "../../../../assets/svg/Plus";
import React, { memo } from "react";
import TextareaAutosize from "../../../answerMessage/textAreaAutoSize";
import TrashSvg from "../../../../assets/svg/Trash";
import { Position } from "@xyflow/react";
import { handleConnectableMax, handlePortId } from "../../config";

const Message = ({
	isLast,
	isOnlyOne,
	hideDupButton,
	index,
	onAddNewMessage,
	onDeleteMessage,
	onMessageChange,
	message,
	mainMessage,
	isEnd,
	onCopyMessage,
	isTarget,
}) => {
	return (
		<div className="sub-message-container">
			<div className="message">
				<div className="node" data-nodeid="NONE">
					<TextareaAutosize
						messageId={mainMessage.id}
						value={message}
						onChange={(message) => onMessageChange(index, message)}
					/>
				</div>
				{!!isLast && (
					<div className="piece-end-point">
						<HandleSource
							id={handlePortId.right}
							position={Position.Right}
							isTarget={isTarget}
							isConnectable={handleConnectableMax.medium}
						/>
						<div className="piece-end-point__drag-helper">
							<img
								className="piece-end-point__drag-helper-img"
								src="/images/help-arrow.png"
								alt="bot management"
							/>
						</div>
					</div>
				)}
				<div className="message-menu">
					<div
						className="message-menu-body"
						style={{
							display: hideDupButton && isOnlyOne && !isEnd ? "none" : "",
						}}
					>
						{!hideDupButton && (
							<button
								className="transparent"
								onClick={() => onCopyMessage(index)}
							>
								<DupSvg />
							</button>
						)}
						{!isOnlyOne && (
							<button
								className="transparent"
								onClick={() => onDeleteMessage(index)}
							>
								<TrashSvg />
							</button>
						)}
						{isEnd && (
							<button
								className="transparent"
								onClick={() => onAddNewMessage(index)}
							>
								<PlusSvg />
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(Message);
