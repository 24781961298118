import React, { useEffect } from "react"

import { useMap } from "@vis.gl/react-google-maps"

const MapHandler = ({ place, onMapChange, SetCenterNow }) => {
  const map = useMap()

  useEffect(() => {
    if (!map || !place) return

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry?.viewport)
    }

    const center = map.getCenter()

    onMapChange({
      lat: center.lat(),
      lng: center.lng(),
    })
    SetCenterNow({
      lat: center.lat(),
      lng: center.lng(),
    })

  }, [map, place, onMapChange, SetCenterNow])

  return null
}

export default React.memo(MapHandler)
