import React, { memo, useMemo } from "react";
import { Handle, getConnectedEdges, useNodeId, useStore } from "@xyflow/react";

const selector = (s) => ({
    nodeLookup: s.nodeLookup,
    edges: s.edges,
});

const CustomHandle = (props) => {
    const { nodeLookup, edges } = useStore(selector);
    const nodeId = useNodeId();

    const isHandleConnectable = useMemo(() => {
        if (typeof props.isConnectable === "function") {
            const node = nodeLookup.get(nodeId);
            const connectedEdges = getConnectedEdges([node], edges);

            return props.isConnectable({ node, connectedEdges });
        }

        if (typeof props.isConnectable === "number") {
            const node = nodeLookup.get(nodeId);
            const connectedEdges = getConnectedEdges([node], edges);

            return connectedEdges.length < props.isConnectable;
        }

        return props.isConnectable;
    }, [props, nodeLookup, nodeId, edges]);

    return <Handle {...props} isConnectable={isHandleConnectable}></Handle>;
};

export default memo(CustomHandle);
