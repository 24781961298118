import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import enLang from 'moment/locale/en-gb'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import moment from 'moment'
import thLang from 'moment/locale/th'

const lang = window.localStorage.getItem('lng') !== null ? window.localStorage.getItem('lng') : 'en'

const onUpdateLocaleMoment = (lng) => {
	switch (lng) {
		case 'en':
			moment.updateLocale('en', enLang)
			break;
		case 'th':
			moment.updateLocale('th', thLang)
			break;
		default:
			moment.updateLocale('en', enLang)
			break;
	}
}

if (moment.locale() !== lang) {
	onUpdateLocaleMoment(lang)
}

i18n
	// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
	// learn more: https://github.com/i18next/i18next-http-backend
	// want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
	.use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		lng: lang,
		fallbackLng: lang,
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		}
	});


export default i18n