import * as actions from "./actions";
import CryptoJS from "crypto-js";
import axios from "axios";
import { store } from "./index";

const DOMAIN =
	process.env.REACT_APP_GLOBAL_DOMAIN || process.env.REACT_APP_DEFAULT_DOMAIN;
const PROTOCAL =
	process.env.REACT_APP_GLOBAL_PROTOCAL ||
	process.env.REACT_APP_DEFAULT_PROTOCAL;

export const API_HOSTNAME = `${PROTOCAL}://api.${DOMAIN}`;
export const EMBEDDED_SDK = `${PROTOCAL}://app.${DOMAIN}/js/script.js`;
export const MAIN_HOSTNAME = `${PROTOCAL}://${DOMAIN}`;
export const APP_HOSTNAME = `${PROTOCAL}://app.${DOMAIN}`;

export const getToken = () => localStorage.getItem("token");
export const getAppId = () => store.getState().system.params.appId;
export const getCurrenctAppId = () => store.getState().system.selectedApp._id;

const IGNORE_401_REDIRECT = ["/login", "/forgot-password", "/reset-password"];

axios.defaults.baseURL = API_HOSTNAME;
axios.defaults.params = {};
axios.interceptors.request.use(
	function (config) {
		const token = getToken();
		if (token !== null && !config.headers.Authorization) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		config.metadata = { startTime: new Date() };
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	function (response) {
		response.config.metadata.endTime = new Date();
		response.duration =
			response.config.metadata.endTime - response.config.metadata.startTime;
		return response;
	},
	function (error) {
		if (axios.isCancel(error)) {
			console.error("[Request canceled] ", error);
			return Promise.reject(null);
		}

		const pathname = window.location.pathname;
		if (
			!IGNORE_401_REDIRECT.includes(pathname) &&
			error.response &&
			401 === error.response.status
		) {
			store.dispatch(actions.unauthorization());
		}

		return Promise.reject(error);
	}
);

export const UploadFile = (file, callback = () => { }, userID) => {
	const data = new FormData();
	data.append("file", file, file.name);

	if (getCurrenctAppId()) {
		return axios
			.post(
				`/v1/apps/${getCurrenctAppId()}/users/${userID}/upload-file`,
				data,
				{
					onUploadProgress: (ProgressEvent) => {
						callback((ProgressEvent.loaded / ProgressEvent.total) * 100);
					},
				}
			)
			.then(({ data }) => {
				return data;
			});
	} else {
		return axios
			.post(`/v1/users/${userID}/upload-file`, data, {
				onUploadProgress: (ProgressEvent) => {
					callback((ProgressEvent.loaded / ProgressEvent.total) * 100);
				},
			})
			.then(({ data }) => {
				return data;
			});
	}
};

export const Login = (email, password) => {
	return axios.post(`/v1/users/login`, {
		email,
		password,
	});
};

export const Register = (email, password, firstName, lastName, recaptcha) => {
	return axios.post(`/v1/users`, {
		email,
		password,
		firstname: firstName,
		lastname: lastName,
		recaptcha,
	});
};

export const credentials = () => axios.get("/v1/credentials");

export const fetchAnalytic = (start, end) =>
	axios.get(`/v1/apps/${getAppId()}/analytic?from=${start}&to=${end}`);

export const fetchDashboard = (start, end, filter) =>
	axios.get(
		`/v1/apps/${getAppId()}/dashboard?from=${start}&to=${end}&filter=${filter}`
	);

export const messages = () => axios.get(`/v1/apps/${getAppId()}/message`);

export const saveMessageConfig = (messages) => {
	return axios.put(
		`/v1/apps/${getAppId()}/message`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				messages,
			}),
			getToken()
		).toString()
	);
};

export const fetchSaveDiagramHistories = () => axios.get(`/v1/apps/${getAppId()}/diagram-histories`);

export const updateSaveDiagramHistories = (id, data) => {
	return axios.put(
		`/v1/apps/${getAppId()}/diagram-histories/${id}`,
		CryptoJS.AES.encrypt(
			JSON.stringify(
				data,
			),
			getToken()
		).toString()
	);
};

export const savePreviewConfig = (messages) => {
	return axios.put(
		`/v1/apps/${getAppId()}/preview-message`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				messages,
			}),
			getToken()
		).toString()
	);
};

export const savePreviewVariables = (variables) => {
	return axios.put(
		`/v1/apps/${getAppId()}/preview-variables`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				variables,
			}),
			getToken()
		).toString()
	);
};

export const publishApp = (messages) => {
	return axios.post(
		`/v1/apps/${getAppId()}/publish`,
		CryptoJS.AES.encrypt(JSON.stringify({}), getToken()).toString()
	);
};

export const fetchApps = () => axios.get(`/v1/apps`);

export const deleteApp = (id) => axios.delete(`/v1/apps/${id}`);

export const fetchSelectedApp = () => axios.get(`/v1/apps/${getAppId()}`);

export const generateInviteUrl = (role) => {
	return axios.post(
		`/v1/apps/${getAppId()}/invitation/link/generate`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				role,
			}),
			getToken()
		).toString()
	);
};

export const fetchInvitedApp = (accessToken) => {
	return axios.get(`/v1/apps/${getAppId()}`, {
		headers: { Authorization: `Bearer ${accessToken}` },
	});
};

export const fetchEditModalConfig = () =>
	axios.get(`/v1/apps/${getAppId()}/edit-modal`);

export const fetchECommerceConfig = () =>
	axios.get(`/v1/apps/${getAppId()}/ecommerce-config`);

export const updateECommerceConfig = (config) => {
	return axios.put(
		`/v1/apps/${getAppId()}/ecommerce-config`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				...config,
			}),
			getToken()
		).toString()
	);
};

export const fullPreviewUrl = () =>
	`${PROTOCAL}://${getAppId()}.${DOMAIN}/preview?token=${getToken()}&app_slug=${getAppId()}`;

export const flexMessageSimulator = () => `${PROTOCAL}://flex.${DOMAIN}`;

export const templatePreview = (templateId, token) =>
	`${PROTOCAL}://${templateId}.${DOMAIN}/template?token=${token}&app_slug=${templateId}`;

export const shareApp = () => `${PROTOCAL}://${getAppId()}.${DOMAIN}`;

export const createApp = (app) => {
	return axios.post(
		`/v1/apps`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				...app,
			}),
			getToken()
		).toString()
	);
};

export const fetchDistributeToken = () =>
	axios.get(`/v1/apps/${getAppId()}/distribute-token`);

export const updateApp = (app) => {
	return axios.put(
		`/v1/apps/${getAppId()}`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				...app,
			}),
			getToken()
		).toString()
	);
};

export const fetchStartOver = () =>
	axios.get(`/v1/apps/${getAppId()}/start-over`);

export const saveStartOver = (config) => {
	return axios.put(
		`/v1/apps/${getAppId()}/start-over`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				...config,
			}),
			getToken()
		).toString()
	);
};

export const saveVariables = (variables) => {
	return axios.put(
		`/v1/apps/${getAppId()}/variables`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				variables,
			}),
			getToken()
		).toString()
	);
};

export const fetchLineConfig = () =>
	axios.get(`/v1/apps/${getAppId()}/line-config`).then(
		({ data }) => data,
		(error) => {
			// when got 404 from server, it means this app has not been linked with google account
			if (error.response.status === 404) {
				return null;
			}
			return Promise.reject(error);
		}
	);

export const saveLineConfig = (config) => {
	return axios.put(
		`/v1/apps/${getAppId()}/line-config`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				...config,
			}),
			getToken()
		).toString()
	);
};

export const refreshLineConfigToken = () => {
	return axios.patch(`/v1/apps/${getAppId()}/line-config/refresh/access_token`);
};

export const fetchTypingEmulation = () =>
	axios.get(`/v1/apps/${getAppId()}/typing-emulation`);

export const fetchMobileAppBuilds = () =>
	axios.get(`/mobile/apps/${getAppId()}/mobile-app-build`);

export const fetchMobileAppConfig = () =>
	axios.get(`/mobile/apps/${getAppId()}/mobile-app-config`);

export const autoGenMobileAppConfig = () => {
	return axios.post(
		`/mobile/apps/${getAppId()}/mobile-app-config/android/auto`,
		CryptoJS.AES.encrypt(JSON.stringify({}), getToken()).toString()
	);
};

export const saveTypingEmulation = (config) => {
	return axios.put(
		`/v1/apps/${getAppId()}/typing-emulation`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				...config,
			}),
			getToken()
		).toString()
	);
};

export const copyApp = (id) => {
	return axios.post(
		`/v1/apps/${id}/copy`,
		CryptoJS.AES.encrypt(JSON.stringify({}), getToken()).toString()
	);
};

export const fetchResponses = (
	platform,
	visitor_id = "",
	limit = 10,
	offset = 0,
	search = "",
	completed = "",
	in_progress = "",
	start_date = "",
	end_date = ""
) => {
	const query = `visitor_id=${visitor_id}&limit=${limit}&offset=${offset}&search=${search}&completed=${completed}&in_progress=${in_progress}&start_date=${start_date}&end_date=${end_date}`;
	// visitor id is defined if open the result page from device id
	const url =
		platform === "line"
			? `/line/apps/${getAppId()}/responses?${query}`
			: `/v1/apps/${getAppId()}/responses?${query}`;
	return axios.get(url);
};

export const fetchResponsesGroupByDevice = () => {
	return axios.get(`/v1/apps/${getAppId()}/responses/by-device`);
};

export const fetchModuleResponses = () => {
	return axios.get(`/line/apps/${getAppId()}/module-responses`);
};

export const fetchMessage = (messageId) => {
	return axios.get(`/v1/apps/${getAppId()}/message/${messageId}`);
};

export const fetchModuleResponseDetail = (messageId) => {
	return axios.get(`/line/apps/${getAppId()}/module-responses/${messageId}`);
};

export const fetchResponsesStats = () =>
	axios.get(`/v1/apps/${getAppId()}/responses/stats`);

export const fetchTemplateConfigs = () => axios.get(`/v1/template-messages`);

export const fetchRoles = (platform) => {
	const url = platform === "line" ? `/line/roles` : `/v1/roles`;
	return axios.get(url);
};

export const saveEditModalConfig = (config) => {
	return axios.put(
		`/v1/apps/${getAppId()}/edit-modal`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				...config,
			}),
			getToken()
		).toString()
	);
};

export const saveIOSAppConfig = (config) => {
	return axios.put(
		`/mobile/apps/${getAppId()}/mobile-app-config/ios`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				...config,
			}),
			getToken()
		).toString()
	);
};

export const saveAndroidAppConfig = (config) => {
	return axios.put(
		`/mobile/apps/${getAppId()}/mobile-app-config/android`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				...config,
			}),
			getToken()
		).toString()
	);
};

export const createNewMobileAppBuild = (config) => {
	return axios.post(
		`/mobile/apps/${getAppId()}/mobile-app-build`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				...config,
			}),
			getToken()
		).toString()
	);
};

export const designConfig = (appId = getAppId()) =>
	axios.get(`/v1/apps/${appId}/design`);

export const updateDesignConfig = (config) => {
	return axios.put(
		`/v1/apps/${getAppId()}/design`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				...config,
			}),
			getToken()
		).toString()
	);
};

export const updateRemovedMessages = (messages) => {
	return axios.put(
		`/v1/apps/${getAppId()}/removed-message`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				messages,
			}),
			getToken()
		).toString()
	);
};

export const removedMessages = () =>
	axios.get(`/v1/apps/${getAppId()}/removed-message`).catch(() => {
		return {
			data: {
				messages: [],
			},
		};
	});

export const setSecret = (value) =>
	axios.post(`/v1/apps/${getAppId()}/secrets`, {
		value: value,
	});

export const requestResetPassword = (email) =>
	axios.post(`/v1/users/forgot`, {
		email,
	});

export const renewToken = (email) => axios.get(`/v1/users/renew-token`);

export const resetPassword = (password, accessToken) => {
	return axios.post(
		`/v1/users/reset-password`,
		{
			password,
		},
		{
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);
};

export const fetchAppVisitors = (query = "all", startFrom = "", limit = 5) =>
	axios.get(
		`/chat/apps/${getAppId()}/rooms?start_from=${startFrom}&limit=${limit}&query=${query}`
	);

export const fetchIntegrationVisitors = (
	query = "all",
	startFrom = "",
	limit = 5
) =>
	axios.get(
		`/chat/rooms?start_from=${startFrom}&limit=${limit}&query=${query}`
	);

export const fetctChatMessages = (appId, roomId, fromId = 0) =>
	axios.get(`/chat/apps/${appId}/rooms/${roomId}/messages?from_id=${fromId}`);

export const sendMessage = (appId, message, roomId) => {
	return axios.post(
		`/chat/apps/${appId}/rooms/${roomId}/send`,
		CryptoJS.AES.encrypt(JSON.stringify(message), getToken()).toString()
	);
};

export const humanTakeoverComplete = (appId, roomId) => {
	return axios.post(
		`/chat/apps/${appId}/rooms/${roomId}/human-takeover/complete`,
		CryptoJS.AES.encrypt(JSON.stringify({}), getToken()).toString()
	);
};

export const humanTakeoverStart = (appId, roomId) => {
	return axios.post(
		`/chat/apps/${appId}/rooms/${roomId}/human-takeover/start`,
		CryptoJS.AES.encrypt(JSON.stringify({}), getToken()).toString()
	);
};

export const humanTakeoverIgnore = (appId, roomId) => {
	return axios.post(
		`/chat/apps/${appId}/rooms/${roomId}/human-takeover/ignore`,
		CryptoJS.AES.encrypt(JSON.stringify({}), getToken()).toString()
	);
};

export const pusherAuth = () => {
	return `${API_HOSTNAME}/chat/auth`;
};

export const fetchResponse = (platform, responseId) => {
	const url =
		platform === "line"
			? `/line/apps/${getAppId()}/responses/${responseId}`
			: `/v1/apps/${getAppId()}/responses/${responseId}`;
	return axios.get(url);
};

export const fetchProducts = () => axios.get(`/v1/apps/${getAppId()}/products`);

export const fetchVariables = () =>
	axios.get(`/v1/apps/${getAppId()}/variables`);

export const createProduct = (name, image, price, description, status, sku) => {
	return axios.post(
		`/v1/apps/${getAppId()}/products`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				name,
				image,
				price,
				description,
				status,
				sku,
			}),
			getToken()
		).toString()
	);
};

export const addCard = (
	number,
	expiration_month,
	expiration_year,
	security_code,
	name
) => {
	return axios.post(
		`/v1/cards`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				number,
				expiration_month,
				expiration_year,
				security_code,
				name,
			}),
			getToken()
		).toString()
	);
};

export const editCard = (expiration_month, expiration_year, name) => {
	return axios.put(
		`/v1/cards`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				expiration_month,
				expiration_year,
				name,
			}),
			getToken()
		).toString()
	);
};

export const updateProduct = (id, name, image, price, description, status) => {
	return axios.put(
		`/v1/apps/${getAppId()}/products/${id}`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				name,
				image,
				price,
				description,
				status,
			}),
			getToken()
		).toString()
	);
};

export const searchCollaborators = (query) =>
	axios.get(`/v1/apps/${getAppId()}/access?email=${query}`);

export const sendInvitation = (email, role) => {
	return axios.post(
		`/v1/apps/${getAppId()}/access`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				email,
				role,
			}),
			getToken()
		).toString()
	);
};

export const fetchCollaborators = () =>
	axios.get(`/v1/apps/${getAppId()}/collaborators`);

export const acceptInvitation = (accessToken, userID) => {
	return axios.post(
		`/v1/apps/${getAppId()}/invitation/accept?user_id=${userID}`,
		CryptoJS.AES.encrypt(JSON.stringify({}), accessToken).toString(),
		{
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);
};

export const declineInvitation = (accessToken, userID) => {
	return axios.delete(
		`/v1/apps/${getAppId()}/invitation/decline?user_id=${userID}`,
		{
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);
};

export const enableChatFeature = () => {
	return axios.post(
		`/v1/apps/${getAppId()}/feature-flags/chat`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				enabled: true,
			}),
			getToken()
		).toString()
	);
};

export const featureFlags = () => {
	return axios.get(`/v1/apps/${getAppId()}/feature-flags`);
};

export const linkWithGoogle = () => {
	return axios.get(`/v1/apps/${getAppId()}/oauth`);
};

export const unlinkWithGoogle = () => {
	return axios.delete(`/v1/apps/${getAppId()}/link-account`);
};

export const googleLinkingDetail = () => {
	return axios.get(`/v1/apps/${getAppId()}/link-account`).then(
		({ data }) => {
			return data;
		},
		(error) => {
			// when got 404 from server, it means this app has not been linked with google account
			if (error.response.status === 404) {
				return null;
			}

			return Promise.reject(error);
		}
	);
};

export const spreadsheets = () => {
	return axios.get(`/v1/apps/${getAppId()}/spreadsheets`);
};

export const sheets = (spreadsheetId) => {
	return axios.get(
		`/v1/apps/${getAppId()}/spreadsheets/${spreadsheetId}/sheets`
	);
};

export const slugValidation = (slug, cancelToken) => {
	return axios.get(`/v1/apps/suggests?slug=${slug}`, {
		signal: cancelToken.signal,
	});
};

export const getPlaceLocation = (lat, lng) => {
	return fetch(
		`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}2&sensor=true&key=AIzaSyD8V4BOnMdcwPLacVsil0mZdNnmCEY_7FM`
	);
};

export const getAllPlans = () => {
	return axios.get("/v1/plans");
};

export const getCards = () => {
	return axios.get("/v1/cards");
};

export const getCard = () => {
	return axios.get("/v1/card");
};

export const getCurrentPlan = () => {
	return axios.get("/v1/current-plan");
};

export const getCurrentAppPlan = () => {
	return axios.get(`/v1/apps/${getAppId()}/current-plan/feature`);
};

export const getCurrenteSchedule = () => {
	return axios.get("/v1/current-schedule");
};

export const changePlan = (planID, billingCycle) => {
	return axios.post(
		`/v1/subscribe`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				plan_id: planID,
				billing_cycle: billingCycle,
			}),
			getToken()
		).toString()
	);
};

export const fetchLineCustomerWithQuery = (
	limit = 10,
	offset = 0,
	search = ""
) => {
	return axios.get(
		`/line/apps/${getAppId()}/customers?limit=${limit}&offset=${offset}&search=${search}`
	);
};

export const banLineCustomer = (id) => {
	return axios.patch(`/line/apps/${getAppId()}/customers/${id}/ban`);
};

export const deleteCard = (cardId) => {
	return axios.delete(`/v1/cards`, {
		data: {
			card_id: cardId,
		},
	});
};

export const getBillingInformation = () => {
	return axios.get("/v1/subscription/billing_information");
};

export const onSaveBillingInformation = (billingInformation) => {
	return axios.post(
		"/v1/subscription/billing_information",
		CryptoJS.AES.encrypt(
			JSON.stringify(billingInformation),
			getToken()
		).toString()
	);
};

export const cancelPlan = (platform) => {
	return axios.post(
		"/v1/subscription/cancel",
		CryptoJS.AES.encrypt(
			JSON.stringify({
				platform: platform,
			}),
			getToken()
		).toString()
	);
};

export const getInvoicesSubscription = (status) => {
	return axios.get(`/v1/subscription/invoices?status=${status}`);
};

export const getInvoicePDF = (token) => {
	return axios.get(`/v1/subscription/invoice/${token}`, {
		responseType: "blob", // Force to receive data in a Blob Format
	});
};

export const payingInvoiceStatusUnpaid = (paymentMethod, invoices) => {
	return axios.post(
		`/v1/subscription/invoice/payment/${paymentMethod}`,
		CryptoJS.AES.encrypt(JSON.stringify(invoices), getToken()).toString()
	);
};

export const fetchLineInsightFriendQuery = (
	limit = 10,
	offset = 0,
	start_date = "",
	end_date = ""
) => {
	const query = `limit=${limit}&offset=${offset}&start_date=${start_date}&end_date=${end_date}`;

	const url = `/line/apps/${getAppId()}/insight/friends?${query}`;
	return axios.get(url);
};

export const fetchLineInsightFriendSummary = (
	start_date = "",
	end_date = ""
) => {
	const query = `start_date=${start_date}&end_date=${end_date}`;

	const url = `/line/apps/${getAppId()}/insight/friends/summary?${query}`;
	return axios.get(url);
};

export const fetchLineInsightMessageQuery = (
	limit = 10,
	offset = 0,
	start_date = "",
	end_date = ""
) => {
	const query = `limit=${limit}&offset=${offset}&start_date=${start_date}&end_date=${end_date}`;

	const url = `/line/apps/${getAppId()}/insight/messages?${query}`;
	return axios.get(url);
};

export const fetchLineInsightMessageSummary = (
	start_date = "",
	end_date = ""
) => {
	const query = `start_date=${start_date}&end_date=${end_date}`;

	const url = `/line/apps/${getAppId()}/insight/messages/summary?${query}`;
	return axios.get(url);
};

export const fetchDataFeedWithQuery = (limit = 10, offset = 0, search = "") => {
	return axios.get(
		`/v1/datafeeds/${getAppId()}?limit=${limit}&offset=${offset}&search=${search}`
	);
};

export const updateInvoice = (id, url) => {
	return axios.put(
		`/v1/subscription/invoice/${id}/image`,
		CryptoJS.AES.encrypt(
			JSON.stringify({
				attach_file_url: url,
			}),
			getToken()
		).toString()
	);
};
