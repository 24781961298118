import * as actions from "../actions";
import * as selectors from "../selectors";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import { put, select, take } from "redux-saga/effects";
import { DIAGRAM_TYPE_FORCE_LAYOUT } from "../../components/diagram/config";
import { useStoreDiagram } from "../../components/diagram/store";
import { getMessageConfigDiagram } from "../../components/diagram/store/global/diagram";

function* onRemoveMessageSaga() {
	while (true) {
		const {
			payload: { node },
		} = yield take(types.ON_REMOVE_MESSAGE);
		try {
			const id = node.id;

			// dont add to removed items list
			if (id === "DRAFT" || node.type === DIAGRAM_TYPE_FORCE_LAYOUT) {
				setTimeout(() => {
					useStoreDiagram.getState().removeNode(id);
				}, 1);

				continue;
			}

			// should can remove start node
			if (id === "START") {
				setTimeout(() => {
					useStoreDiagram.getState().addNewNodeInterface([node]);
				}, 1);

				continue;
			}

			const messages = getMessageConfigDiagram();
			if (!messages[id]) {
				continue;
			}

			const removedMessages = yield select(selectors.getRemovedMessages);

			const newRemovedMessage = {
				...messages[id],
			};

			yield put(
				actions.removedMessages([...removedMessages, newRemovedMessage])
			);

			const uniqueID = yield select(selectors.getUniqueID);

			setTimeout(() => {
				useStoreDiagram.getState().removeNode(id);
				useStoreDiagram.getState().resetSelectedNode(uniqueID);
			}, 1);
		} catch (err) {
			console.error("[onRemoveMessageSaga] ", err);
			eventlog.error("remove message", {
				message: err.message,
			});
		}
	}
}

export default onRemoveMessageSaga;
