import acceptInvitation from "./acceptInvitation";
import addCard from "./addCard";
import autoGenMobileAppConfig from "./autoGenMobileAppConfig";
import banLineCustomer from "./banLineCustomer";
import cancelAutoRenewPlan from "./cancelAutoRenewPlan";
import changePlan from "./changePlan";
import copyApp from "./copyApp";
import createNewBot from "./createNewBot";
import createProduct from "./createProduct";
import declineInvitation from "./declineInvitation";
import deleteApp from "./deleteApp";
import deleteMessage from "./deleteMessage";
import editCard from "./editCard";
import enableFeatureFlag from "./enableFeatureFlag";
import enterAppManagement from "./enterAppManagement";
import enterBotManagement from "./enterBotManagement";
import enterInvitationPage from "./enterInvitationPage";
import enterModuleResponseDetail from "./enterModuleResponseDetail";
import enterSubscription from "./enterSubscription";
import fetchAnalytic from "./fetchAnalytic";
import fetchDashboard from "./fetchDashboard";
import fetchDatafeedWithQuery from "./fetchDatafeedWithQuery";
import fetchDesignConfig from "./fetchDesignConfig";
import fetchGoogleLinkingDetail from "./fetchGoogleLinkingDetail";
import fetchLineConfig from "./fetchLineConfig";
import fetchLineCustomerWithQuery from "./fetchLineCustomerWithQuery";
import fetchLineInsightFriendQuery from "./fetchLineInsightFriendQuery";
import fetchLineInsightMessageQuery from "./fetchLineInsightMessageQuery";
import fetchMessageConfig from "./fetchMessageConfig";
import fetchMobileAppBuilds from "./fetchMobileAppBuilds";
import fetchModuleResponses from "./fetchModuleResponses";
import fetchProducts from "./fetchProducts";
import fetchPublicData from "./fetchPublicData";
import fetchRemovedMessages from "./fetchRemovedMessages";
import fetchResponse from "./fetchResponse";
import fetchResponsesGroupByDevice from "./fetchResponsesGroupByDevice";
import fetchResponsesSaga from "./fetchResponsesSaga";
import fetchResponsesStats from "./fetchResponsesStats";
import fetchRoles from "./fetchRoles";
import fetchSaveDiagramHistory from "./fetchSaveDiagramHistory";
import fetchSettings from "./fetchSettings";
import fetchVariables from "./fetchVariables";
import generateInvitationURL from "./generateInvitationURL";
import getInvoicePDF from "./getInvoicePDF";
import humanTakeoverComplete from "./humanTakeoverComplete";
import humanTakeoverIgnore from "./humantakeoverIgnore";
import humanTakeoverStart from "./humantakeoverStart";
import linkWithGoogle from "./linkWithGoogle";
import onConfirmEditMap from "./onConfirmEditMap";
import onDeleteCard from "./onDeleteCard";
import onEnterBillingInformation from "./onEnterBillingInformation";
import onEnterCollaboratorsPage from "./onEnterCollaboratorsPage";
import onEnterDistribute from "./onEnterDistribute";
import onFetchApps from "./onFetchApps";
import onFetchOlderMessages from "./onFetchOlderMessages";
import onRemoveMessageSaga from "./onRemoveMessageSaga";
import onSearchCollaborators from "./onSearchCollaborators";
import onSelectApp from "./onSelectApp";
import onSelectMessengerVisitor from "./onSelectMessengerVisitor";
import onSelectNode from "./onSelectNode";
import onSendMessage from "./onSendMessage";
import onSubmitEditMessage from "./onSubmitEditMessage";
import payingInvoiceStatusUnpaid from "./payingInvoiceStatusUnpaid";
import postMessageToIframe from "./postMessageToIframe";
import previewSaveDiagramHistory from "./previewSaveDiagramHistory";
import publishApp from "./publishApp";
import refreshLineConfigToken from "./refreshLineConfigToken";
import register from "./register";
import renewToken from "./renewToken";
import requestResetPassword from "./requestResetPassword";
import resetPassword from "./resetPassword";
import resetSaveDiagramHistory from "./resetSaveDiagramHistory";
import retryMessage from "./retryMessage";
import saveAppConfig from "./saveAppConfig";
import saveBillingInformation from "./saveBillingInformation";
import saveDiagramHistoryUpdate from "./saveDiagramHistoryUpdate";
import saveEditModalConfig from "./saveEditModalConfig";
import saveLineConfig from "./saveLineConfig";
import saveMessageConfig from "./saveMessageConfig";
import saveMobileAppConfig from "./saveMobileAppConfig";
import savePreviewConfig from "./savePreviewConfig";
import savePreviewInBackground from "./savePreviewInBackground";
import saveStartOver from "./saveStartOver";
import saveTypingEmulation from "./saveTypingEmulation";
import saveUploadMedia from "./saveUploadMedia";
import saveVariables from "./saveVariables";
import selectSaveDiagramHistory from "./selectSaveDiagramHistory";
import sendCollaboratorInvitation from "./sendCollaboratorInvitation";
import signin from "./signin";
import signout from "./signout";
import startApp from "./startApp";
import unauthorization from "./unauthorization";
import unlinkWithGoogle from "./unlinkWithGoogle";
import unsubcribeChannel from "./unsubcribeChannel";
import updateECommerceConfig from "./updateECommerceConfig";
import updateInvoice from "./updateInvoice";
import updateProduct from "./updateProduct";
import validateSlug from "./validateSlug";
import { all } from "redux-saga/effects";

import {
	onLoadMoreAppVisitors,
	onLoadMoreIntegrationVisitors,
	startAppChat,
	startIntegrationChat,
} from "./startChat";

// single entry point to start all Sagas at once
export default function* rootSaga() {
	yield all([
		startApp(),
		fetchMessageConfig(),
		startIntegrationChat(),
		onLoadMoreIntegrationVisitors(),
		signin(),
		signout(),
		onFetchApps(),
		onSelectApp(),
		saveEditModalConfig(),
		createNewBot(),
		enterBotManagement(),
		enterAppManagement(),
		saveAppConfig(),
		fetchResponsesSaga(),
		fetchRemovedMessages(),
		onSelectNode(),
		saveUploadMedia(),
		onConfirmEditMap(),
		deleteApp(),
		copyApp(),
		register(),
		fetchDesignConfig(),
		postMessageToIframe(),
		fetchSettings(),
		saveStartOver(),
		requestResetPassword(),
		resetPassword(),
		fetchPublicData(),
		onEnterDistribute(),
		renewToken(),
		startAppChat(),
		onSelectMessengerVisitor(),
		onSendMessage(),
		onFetchOlderMessages(),
		onLoadMoreAppVisitors(),
		unsubcribeChannel(),
		retryMessage(),
		deleteMessage(),
		fetchResponsesStats(),
		fetchResponse(),
		onSearchCollaborators(),
		sendCollaboratorInvitation(),
		acceptInvitation(),
		declineInvitation(),
		enterInvitationPage(),
		onEnterCollaboratorsPage(),
		linkWithGoogle(),
		unlinkWithGoogle(),
		fetchGoogleLinkingDetail(),
		saveTypingEmulation(),
		validateSlug(),
		saveMobileAppConfig(),
		publishApp(),
		saveLineConfig(),
		fetchLineConfig(),
		fetchModuleResponses(),
		fetchProducts(),
		createProduct(),
		enterModuleResponseDetail(),
		enableFeatureFlag(),
		unauthorization(),
		updateProduct(),
		updateECommerceConfig(),
		fetchVariables(),
		saveVariables(),
		fetchMobileAppBuilds(),
		autoGenMobileAppConfig(),
		fetchResponsesGroupByDevice(),
		humanTakeoverComplete(),
		humanTakeoverStart(),
		humanTakeoverIgnore(),
		fetchAnalytic(),
		fetchDashboard(),
		enterSubscription(),
		addCard(),
		editCard(),
		changePlan(),
		banLineCustomer(),
		fetchLineCustomerWithQuery(),
		refreshLineConfigToken(),
		fetchRoles(),
		generateInvitationURL(),
		onDeleteCard(),
		saveBillingInformation(),
		onEnterBillingInformation(),
		fetchLineInsightFriendQuery(),
		fetchLineInsightMessageQuery(),
		cancelAutoRenewPlan(),
		getInvoicePDF(),
		payingInvoiceStatusUnpaid(),
		fetchDatafeedWithQuery(),
		updateInvoice(),
		onSubmitEditMessage(),
		onRemoveMessageSaga(),
		savePreviewConfig(),
		savePreviewInBackground(),
		saveMessageConfig(),
		fetchSaveDiagramHistory(),
		saveDiagramHistoryUpdate(),
		previewSaveDiagramHistory(),
		selectSaveDiagramHistory(),
		resetSaveDiagramHistory(),
	]);
}
