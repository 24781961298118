import DupSvg from "../../../../assets/svg/Duplicate";
import EditSvg from "../../../../assets/svg/Edit";
import HandleSource from "../system/handle/handleSource";
import PlusSvg from "../../../../assets/svg/Plus";
import React, { memo } from "react";
import TrashSvg from "../../../../assets/svg/Trash";
import { Position } from "@xyflow/react";
import { handleConnectableMax, handlePortId } from "../../config";

const Media = ({
	isLast,
	message,
	image,
	isOnlyOne,
	index,
	onAddNewMessage,
	onDeleteMessage,
	onCopyMessage,
	onChangeDraftUploadMedia,
	isTarget,
	nodeId,
}) => {
	return (
		<div className="sub-message-container">
			<div className="media">
				<img
					alt=""
					draggable="false"
					className="node"
					data-nodeid="NONE"
					src={image || "/images/picture-placeholder.png"}
				/>
				<div className="edit-container">
					<button
						onClick={() => {
							onChangeDraftUploadMedia({
								nodeId: nodeId,
								messageIndex: index,
								type: "FILE_UPLOAD",
								fileType: "IMAGE",
								accept: "image/x-png, image/png, image/gif,image/jpeg",
							});
						}}
					>
						<EditSvg />
					</button>
				</div>
				{!!isLast && (
					<div className="piece-end-point">
						<HandleSource
							id={handlePortId.right}
							position={Position.Right}
							isTarget={isTarget}
							isConnectable={handleConnectableMax.medium}
						/>
						<div className="piece-end-point__drag-helper">
							<img
								alt=""
								className="piece-end-point__drag-helper-img"
								src="/images/help-arrow.png"
							/>
						</div>
					</div>
				)}
				<div className="message-menu">
					<div className="message-menu-body">
						<button
							className="transparent"
							onClick={() => onCopyMessage(index)}
						>
							<DupSvg />
						</button>
						{!isOnlyOne && (
							<button
								className="transparent"
								onClick={() => onDeleteMessage(index)}
							>
								<TrashSvg />
							</button>
						)}
						<button
							className="transparent"
							onClick={() => onAddNewMessage(index)}
						>
							<PlusSvg />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(Media);
