import HandleSource from "../system/handle/handleSource";
import React, { memo } from "react";
import { Position } from "@xyflow/react";
import { handleConnectableMax, handlePortId } from "../../config";

const Start = ({ isHasIntroBot, mode, isTarget }) => {
	return (
		<div className="sub-message-container">
			<div className="message">
				<div className="node" data-nodeid="NONE"></div>
				<div className="piece-end-point">
					<HandleSource
						id={handlePortId.right}
						position={Position.Right}
						isTarget={isTarget}
						isConnectable={handleConnectableMax.medium}
					/>
					<div className="piece-end-point__drag-helper">
						<img
							className="piece-end-point__drag-helper-img"
							src="/images/help-arrow.png"
							alt="bot management"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(Start);
