import ContextualZoom from "../system/contextualZoom";
import HandleTarget from "../system/handle/handleTarget";
import React, { memo } from "react";
import UnionMessage from "../unionMessage";
import { Position } from "@xyflow/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
	contextualZoomSize,
	contextualZoomStyle,
	handleConnectableMax,
	handlePortId,
	nodeId,
} from "../../config";

const UnionMessageNode = ({
	message,
	onHandleUpdate,
	isTarget,
	copyComponentNode,
	removeComponentNode,
	copyToClipBoard,
	removeHandles,
	resetHandles,
	addHandles,
}) => {
	const searchString = "END";
	const indexOfEND = message.id.indexOf(searchString);
	const { t } = useTranslation(["subscription"]);

	const isHasIntroBot = useSelector((state) => state.messageConfig.isHasIntro);

	return (
		<div id={`node-${message.id}`} style={contextualZoomStyle} className="custom-drag-handle">
			{message.id === nodeId.START && !isHasIntroBot && (
				<div className="start-here-image"></div>
			)}
			{indexOfEND !== -1 && <div className="end-here-image"></div>}
			<ContextualZoom
				isTarget={isTarget}
				size={
					message.id === nodeId.START
						? contextualZoomSize.small
						: indexOfEND !== -1
							? contextualZoomSize.small
							: contextualZoomSize.large
				}
			>
				{message.id !== nodeId.START && (
					<div className={isTarget ? "entry-port-container" : ""}>
						<HandleTarget
							id={handlePortId.entry}
							position={Position.Left}
							isTarget={isTarget}
							isConnectable={handleConnectableMax.medium}
						/>
					</div>
				)}
				<UnionMessage
					config={message}
					onUpdate={onHandleUpdate}
					isStart={message.id === nodeId.START}
					t={t}
					isHasIntroBot={isHasIntroBot}
					isTarget={isTarget}
					copyComponentNode={copyComponentNode}
					removeComponentNode={removeComponentNode}
					copyToClipBoard={copyToClipBoard}
					removeHandles={removeHandles}
					resetHandles={resetHandles}
					addHandles={addHandles}
				/>
			</ContextualZoom>
		</div>
	);
};

export default memo(UnionMessageNode);
