import { edgeForceLayoutProps } from "../../config";
import { getToolDiagram } from "../global/diagram";

export const addEdgeForceLayout = (set, get, edges) => {
	try {
		const data = edges.map((edgeNow) => {
			return {
				...edgeForceLayoutProps,
				source: edgeNow.source.nodeId,
				target: edgeNow.target.nodeId,
				id: `${crypto.randomUUID()}`,
			};
		});
		
		getToolDiagram("setEdges")((eds) => [...eds, ...data]);
	} catch (err) {
		console.error("[addEdgeForceLayout] ", err);
	}
}
