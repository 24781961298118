import * as constants from "../../../../constants";
import ContextualZoom from "../system/contextualZoom";
import CopyIcon from "../../../../images/copy";
import DuplicateIcon from "../../../../images/duplicate";
import React, { memo, useCallback, useState } from "react";
import TrashIcon from "../../../../images/trash";
import useDetachNodes from "../../feature/dynamicGrouping/useDetachNodes";
import { NodeResizer, NodeToolbar, useStore, useStoreApi } from "@xyflow/react";
import { useTranslation } from "react-i18next";
import { getRelativeNodesBounds } from "../../feature/dynamicGrouping/utils";

import {
	contextualZoomNodeGroupStyle,
	contextualZoomSize,
	groupStyle,
} from "../../config";

function isEqual(prev, next) {
	return (
		prev.minWidth === next.minWidth &&
		prev.minHeight === next.minHeight &&
		prev.hasChildNodes === next.hasChildNodes
	);
}

const DiagramGroup = ({
	message,
	copyComponentNode,
	removeComponentNode,
	copyToClipBoard,
	detail,
	onHandleUpdate,
	currentAppPlan,
	isTarget,
	id,
}) => {
	const [helptext, setHelptext] = useState("");
	const { t } = useTranslation(["subscription"]);
	const tBoard = useTranslation(["board"]).t

	const handleUpdateHelpText = useCallback(() => {
		onHandleUpdate({
			helptext: helptext,
		});
	}, [onHandleUpdate, helptext]);

	const proPlan = currentAppPlan.pro.some((item) => item === message.mode);
	const starterPlan = currentAppPlan.starter.some(
		(item) => item === message.mode
	);

	const store = useStoreApi();
	const detachNodes = useDetachNodes();
	const { minWidth, minHeight, hasChildNodes } = useStore((store) => {
		const childNodes = Array.from(store.nodeLookup.values()).filter(
			(n) => n.parentId === id
		);
		const rect = getRelativeNodesBounds(childNodes);

		return {
			minWidth: rect.x + rect.width,
			minHeight: rect.y + rect.height,
			hasChildNodes: childNodes.length > 0,
		};
	}, isEqual);

	const onDetach = () => {
		const childNodeIds = Array.from(store.getState().nodeLookup.values())
			.filter((n) => n.parentId === id)
			.map((n) => n.id);

		detachNodes(childNodeIds, id);
	};

	return (
		<div>
			<NodeResizer
				lineStyle={groupStyle.nodeResizer}
				minHeight={minHeight}
				minWidth={minWidth}
			/>
			<NodeToolbar className="nodrag">
				{hasChildNodes && <button onClick={onDetach}>{tBoard("group.Ungroup")}</button>}
			</NodeToolbar>
			<div
				className="question-message-block custom-drag-handle"
				id={`node-${message.id}`}
				style={contextualZoomNodeGroupStyle}
			>
				<ContextualZoom isTarget={false} size={contextualZoomSize.medium}>
					<div className="message-menu">
						<div className="message-menu-body">
							<h6 className="message-id">{message.id}</h6>
							<button
								className="transparent"
								onClick={() => copyToClipBoard(message.id)}
							>
								<CopyIcon />
							</button>
							<button
								className="transparent"
								onClick={() => copyComponentNode(message.id)}
							>
								<DuplicateIcon />
							</button>
							<button
								className="transparent"
								onClick={() => removeComponentNode(message.id)}
							>
								<TrashIcon />
							</button>
						</div>
					</div>

					<div className="header">
						{proPlan && <div className="plan-tag">PRO</div>}
						{starterPlan && <div className="plan-tag">STARTER</div>}
						<div className="icon">
							{!!detail.image && (
								<div
									className="img"
									style={{
										backgroundImage: `url('${detail.image}')`,
									}}
								/>
							)}
							{!!detail.icon && detail.icon}
						</div>
						<div className="detail">
							<h6 style={{ marginLeft: "12px" }}>
								{t(`features.${detail.slug}`)}
							</h6>
							<p
								className="nodrag"
								contentEditable="true"
								suppressContentEditableWarning={true}
								onInput={(e) => {
									setHelptext(e.currentTarget.textContent);
								}}
								onBlur={handleUpdateHelpText}
							>
								{message.helptext ||
									message.question ||
									t(`${constants.defaultHelptext}`)}
							</p>
						</div>
					</div>
				</ContextualZoom>
			</div>
		</div>
	);
};

export default memo(DiagramGroup);
