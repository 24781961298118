import React, { memo } from "react";

const Placeholder = ({ size }) => (
    <div className="react-flow_placeholder">
        {new Array(size).fill(0).map(() => (
            <div key={`${crypto.randomUUID()}`} />
        ))}
    </div>
);

export default memo(Placeholder);
