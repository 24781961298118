import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { all, call, put, select, take } from 'redux-saga/effects'
import { fetchMobileAppBuilds, fetchMobileAppConfig } from './onEnterDistribute'

import eventlog from '../../modules/eventlog'

function* saveMobileAppConfig() {
	while (true) {
		yield take(types.SAVE_MOBILE_APP_CONFIG)
		try {
			eventlog.info('save mobile app config')
			yield put(actions.isSavingMobileAppConfig(true))
			const mobileAppConfig = yield select(selectors.getMobileAppConfig)
			
			if (mobileAppConfig.platform === 'ios') {
				yield call(api.saveIOSAppConfig, mobileAppConfig)
			} else if (mobileAppConfig.platform === 'android') {
				yield call(api.saveAndroidAppConfig, mobileAppConfig)
			}

			yield call(api.createNewMobileAppBuild, {
				version: mobileAppConfig.version,
				platform: mobileAppConfig.platform,
			})

			const toastManager = yield select(selectors.getToast)
			toastManager.success('Saved successfully')

			yield all([fetchMobileAppBuilds(), fetchMobileAppConfig()])
			
			const app = yield select(selectors.getSelectedApp)
			yield put(actions.redirect(`/${app.slug}/distribute`))
			yield put(actions.isSavingMobileAppConfig(false))
		} catch (err) {
			console.error('[saveMobileAppConfig] ', err)
			yield put(actions.isSavingMobileAppConfig(false))
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			eventlog.error('save mobile app config', {
				message: err.message
			})
		}
	}
}

export default saveMobileAppConfig
