import { edgeProps } from "../../config";
import { getToolDiagram } from "../global/diagram";

export const addEdge = (set, get, edges) => {
	try {
		const data = edges.map((edgeNow) => {
			return {
				...edgeProps,
				source: edgeNow.source.nodeId,
				sourceHandle: edgeNow.source.handleId,
				target: edgeNow.target.nodeId,
				targetHandle: edgeNow.target.handleId,
				id: `${crypto.randomUUID()}`,
			};
		});
		
		getToolDiagram("setEdges")((eds) => [...eds, ...data]);
	} catch (err) {
		console.error("[addEdge] ", err);
	}
}
