import React, { memo } from "react";

const End = ({ isTarget }) => {
	return (
		<div className="sub-message-container">
			<div className="message">
				<div className="node" data-nodeid="NONE"></div>
			</div>
		</div>
	);
};

export default memo(End);
