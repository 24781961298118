const getMessageWithTriggers = (nodes, edges) => {
	return Object.entries(nodes)
		.filter(([key, node]) => node.id !== "NONE")
		.map(([index, node]) => {
			const triggers = Object.entries(node.handles)
				.filter(([key, port]) => key !== "entry" && key !== "left")
				.reduce((o, [key, port]) => {
					let edge = edges.filter(
						(edge) => edge.source === node.id && edge.sourceHandle === key
					);

					if (edge.length > 0) {
						edge = edge[0];
					} else if (edge.length === 0) {
						edge.target = null;
					}

					const config = edge.target
						? {
							trigger: edge.target,
						}
						: {
							trigger: null,
						};

					if (!config.trigger) {
						return config;
					} else {
						return {
							trigger: {
								...o.trigger,
								[key]: config.trigger,
							},
						};
					}
				}, {});

			const result = {
				...node,
				...triggers,
			};

			delete result["dataOther"];
			delete result["measured"];
			delete result["height"];
			delete result["width"];
			delete result["positionAbsolute"];
			delete result["targetPosition"];
			delete result["sourcePosition"];
			delete result["dragging"];
			delete result["points"];
			delete result["internals"];

			return result;
		});
};

export default getMessageWithTriggers;
