import "./style.scss";
import React, { memo, useCallback, useEffect } from "react";
import { Handle, Position } from "@xyflow/react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { nodeForceLayoutModes } from "../config/index.js";
import { useStoreDiagram } from "../store/index.js";

const handleStyle = {
	left: "50%",
	top: "50%",
	transform: "translate(-50%, -50%)",
	opacity: 0,
	pointerEvents: "none",
};

function ComponentNode(props) {
	const { id, data } = props;
	const mode = data.mode;
	const { t } = useTranslation(["editMessage"]);

	const { createDraftForceLayoutNodeChild, createNodeByMessageSelection } =
		props;

	const setWrapperRefForceLayout = (node) => {
		setTimeout(() => {
			window.wrapperRefForceLayout = node;
		}, 100);
	};

	const handlerClickChild = useCallback(() => {
		createNodeByMessageSelection(
			data.slug,
			window.mousePositionDraftNow.current,
			true
		);
	}, [createNodeByMessageSelection, data.slug]);

	const handlerClickCategory = useCallback(() => {
		createDraftForceLayoutNodeChild(
			id,
			data.slug,
			window.mousePositionDiagram.current
		);
	}, [createDraftForceLayoutNodeChild, data.slug, id]);

	const handlerClickManage = useCallback(() => {
		if (mode === nodeForceLayoutModes.CATEGORY) {
			handlerClickCategory();
		} else if (mode === nodeForceLayoutModes.CHILD) {
			handlerClickChild();
		}
	}, [handlerClickCategory, handlerClickChild, mode]);

	useEffect(() => {
		const elementsNode = document.querySelectorAll(`[data-id="${id}"]`);
		elementsNode.forEach((element) => {
			element.addEventListener("click", handlerClickManage);
			setWrapperRefForceLayout(element);
		});

		return () => {
			elementsNode.forEach((element) => {
				element.removeEventListener("click", handlerClickManage);
			});
		};
	}, [handlerClickManage, id]);

	const tooltipID = `tooltip-${id}`;
	const handleMock = () => {
		return (
			<>
				<Handle style={handleStyle} type="target" position={Position.Left} />
				<Handle style={handleStyle} type="source" position={Position.Right} />
			</>
		);
	};

	if (mode === nodeForceLayoutModes.MAIN) {
		return <div id={`node-${id}`}>{handleMock()}</div>;
	} else if (mode === nodeForceLayoutModes.CATEGORY) {
		return (
			<div id={`node-${id}`}>
				<div>{t(`category.${data.label}`)}</div>
				{handleMock()}
			</div>
		);
	} else if (mode === nodeForceLayoutModes.CHILD) {
		return (
			<>
				<ReactTooltip id={tooltipID} />
				<div
					id={`node-${id}`}
					data-tooltip-id={tooltipID}
					data-tooltip-content={t(
						`messageNameAndDescription.${data.slug}.name`
					)}
				>
					{!data.icon ? (
						<img
							alt=""
							src={data.image}
							style={{
								width: "70px",
								height: "70px",
							}}
						/>
					) : (
						data.icon
					)}
					{handleMock()}
				</div>
			</>
		);
	} else {
		return <div id={`node-${id}`}></div>;
	}
}

const mapStateToProps = (state) => ({
	selectedApp: state.system.selectedApp,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	createDraftForceLayoutNodeChild: (id, category, position) => {
		useStoreDiagram
			.getState()
			.createDraftForceLayoutNodeChild(id, category, position);
	},
	createNodeByMessageSelection: (mode, position, forceLayout) => {
		useStoreDiagram
			.getState()
			.createNodeByMessageSelection(mode, position, forceLayout);
	},
});

const Container = connect(mapStateToProps, mapDispatchToProps)(ComponentNode);

export default memo(Container);
