import "./style.scss";
import * as React from "react";
import * as actions from "../../../../redux/actions";
import * as constants from "../../../../constants";
import Audio from "./audio";
import Button from "./button";
import CopyIcon from "../../../../images/copy";
import DupSvg from "../../../../images/duplicate";
import End from "./end";
import Map from "./map";
import Media from "./media";
import Message from "./message";
import Start from "./start";
import ThreeDot from "../../../../assets/svg/ThreeDot";
import TrashSvg from "../../../../assets/svg/Trash";
import Video from "./video";
import cloneDeep from "../../../../utils/cloneDeep";
import generateID from "../../../../utils/generateID";
import styles from "./union-message.module.scss";
import { connect } from "react-redux";
import { nodeId } from "../../config";

class UnionMessage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...props.config,
			helptext: "",
		};
	}

	updateStore = () => {
		const { onUpdate } = this.props;
		onUpdate({
			mode: this.state.mode,
			messages: [...this.state.messages],
		});
	};

	onChangeMode = (mode, index) => {
		let {
			config: { messages },
		} = this.props;
		if (mode === "MEDIA") {
			messages[index] = {
				mode: "MEDIA",
				image: "",
				trigger: "",
			};
		} else if (mode === "MESSAGE") {
			messages[index] = {
				mode: "MESSAGE",
				message: "",
				trigger: "",
			};
		} else if (mode === "AUDIO") {
			messages[index] = {
				mode: "AUDIO",
				audio: "",
				trigger: "",
			};
		} else if (mode === "VIDEO") {
			messages[index] = {
				mode: "VIDEO",
				video: "",
				trigger: "",
			};
		} else if (mode === "MAP") {
			messages[index] = {
				mode: "MAP",
				trigger: "",
				location: {
					name: null,
					lat: Number(process.env.REACT_APP_DEFAULT_LOCATION_LAT),
					lng: Number(process.env.REACT_APP_DEFAULT_LOCATION_LNG),
				},
			};
		} else if (mode === "BUTTON") {
			const id = generateID();
			messages.splice(index, 1);
			messages = [
				...messages,
				{
					mode: "BUTTON",
					input: {
						layout: "vertical",
						options: [
							{
								label: "",
								value: "",
								trigger: "",
								image:
									"https://i.ibb.co/3TZkMH7/iconfinder-help-question-293438.png",
								id: id,
							},
						],
						type: "ITEM",
					},
				},
			];

			/** remove old ports and create the new ports */
			this.props.resetHandles(this.state.id, [id, "default"]);
		}

		this.setState(
			{
				messages: messages,
			},
			() => {
				this.updateStore();
			}
		);
	};

	onAddNewMessage = (index) => {
		const {
			config: { messages },
		} = this.props;
		messages.splice(index + 1, 0, {
			mode: "MESSAGE",
			message: "",
			trigger: "",
		});

		this.setState(
			{
				messages: [...messages],
			},
			() => {
				this.updateStore();
			}
		);
	};

	onCopyMessage = (index) => {
		const {
			config: { messages },
		} = this.props;
		const sourceMessage = {
			...messages[index],
		};

		messages.splice(index + 1, 0, sourceMessage);
		this.setState(
			{
				messages: [...messages],
			},
			() => {
				this.updateStore();
			}
		);
	};

	onMessageChange = (index, message) => {
		const {
			config: { messages },
		} = this.props;
		messages[index].message = message;
		this.setState(
			{
				messages: [...messages],
			},
			() => {
				this.updateStore();
			}
		);
	};

	onQuestionChange = (index, message) => {
		const {
			config: { messages },
		} = this.props;
		messages[index].question = message;
		this.setState(
			{
				messages: [...messages],
			},
			this.updateStore
		);
	};

	onTitleChange = (index, message) => {
		const {
			config: { messages },
		} = this.props;
		messages[index].location.title = message;
		this.setState(
			{
				messages: [...messages],
			},
			this.updateStore
		);
	};

	onDeleteMessage = (index) => {
		const {
			config: { messages },
		} = this.props;
		messages.splice(index, 1);
		this.setState(
			{
				messages: [...messages],
			},
			() => {
				this.updateStore();
			}
		);
	};

	onDeleteButtons = (index) => {
		this.onDeleteMessage(index);

		/** reset ports after all buttons are removed */
		if (!this.hasButton()) {
			this.props.resetHandles(this.state.id, ["right"]);
		}
	};

	hasButtonMessage = () => {
		const {
			config: { messages },
		} = this.props;
		return messages.some((message) => {
			return message.mode === "BUTTON";
		});
	};

	onAddButtonItem = (index) => {
		/** create the new ports */
		const id = generateID();

		const {
			config: { messages },
		} = this.props;
		const lastIndex = messages.length - 1;
		messages[lastIndex].input.options.splice(index + 1, 0, {
			label: "",
			value: "",
			trigger: "",
			image: "https://i.ibb.co/3TZkMH7/iconfinder-help-question-293438.png",
			id: id,
		});

		this.setState(
			{
				messages: [...messages],
			},
			() => {
				this.updateStore();
			}
		);

		this.props.addHandles(this.state.id, [id])
	};

	onCopyButtonItem = (index) => {
		/** create the new ports */
		const id = generateID();

		const {
			config: { messages },
		} = this.props;
		const lastIndex = messages.length - 1;

		const sourceMessageItem = {
			...messages[lastIndex].input.options[index],
			id: id,
		};

		messages[lastIndex].input.options.splice(index + 1, 0, sourceMessageItem);

		this.setState(
			{
				messages: [...messages],
			},
			() => {
				this.updateStore();
			}
		);

		this.props.addHandles(this.state.id, [id])
	};

	onDeleteButtonItem = (index) => {
		const {
			config: { messages },
		} = this.props;
		const lastIndex = messages.length - 1;

		/** remove the new ports */
		const id = messages[lastIndex].input.options[index].id;
		this.props.removeHandles(this.state.id, [id]);

		messages[lastIndex].input.options.splice(index, 1);

		if (messages[lastIndex].input.options.length === 0) {
			messages.splice(lastIndex, 1);
		}

		/** reset ports after all buttons are removed */
		if (!this.hasButton()) {
			this.props.resetHandles(this.state.id, ["right"]);
		}

		this.setState(
			{
				messages: [...messages],
			},
			() => {
				this.updateStore();
			}
		);
	};

	onButtonItemMessageChange = (index, text) => {
		const {
			config: { messages },
		} = this.props;
		const lastIndex = messages.length - 1;
		messages[lastIndex].input.options[index].label = text;

		// const value = messages[lastIndex].input.options[index].value

		this.setState(
			{
				messages: [...messages],
			},
			() => {
				this.updateStore();
			}
		);
	};

	renderTypeSelection = (mode, index, hasButtonMessage, hasMoreTwoMessage) => {
		return (
			<div className="add-container">
				<button
					onClick={() => this.onChangeMode("MESSAGE", index)}
					className={
						mode === "MESSAGE" ? "transparent selected" : "transparent"
					}
				>
					<i className="fas fa-font"></i>
				</button>
				<button
					onClick={() => this.onChangeMode("MEDIA", index)}
					className={mode === "MEDIA" ? "transparent selected" : "transparent"}
				>
					<i className="fas fa-image"></i>
				</button>
				<button
					onClick={() => this.onChangeMode("AUDIO", index)}
					className={mode === "AUDIO" ? "transparent selected" : "transparent"}
				>
					<i className="fas fa-microphone"></i>
				</button>
				<button
					onClick={() => this.onChangeMode("VIDEO", index)}
					className={mode === "VIDEO" ? "transparent selected" : "transparent"}
				>
					<i className="fas fa-video"></i>
				</button>
				<button
					onClick={() => this.onChangeMode("MAP", index)}
					className={mode === "MAP" ? "transparent selected" : "transparent"}
				>
					<i className="fas fa-map-marker"></i>
				</button>
				{!hasButtonMessage && (
					<button
						onClick={() => this.onChangeMode("BUTTON", index)}
						className={
							mode === "BUTTON" ? "transparent selected" : "transparent"
						}
					>
						<i className="fas fa-navicon"></i>
					</button>
				)}
			</div>
		);
	};

	isOnlyOne = () => {
		// not include checkbox
		const {
			config: { messages },
		} = this.props;
		return messages.length === 1;
	};

	hasMoreTwoMessage = () => {
		const {
			config: { messages },
		} = this.props;
		return this.hasButton() ? messages.length - 1 > 1 : messages.length > 1;
	};

	renderTimeline = (number, isLast) => {
		return (
			<div className={styles.timeline}>
				{!isLast && <div className={styles.line} />}
				<div className={styles.step}>{number}</div>
			</div>
		);
	};

	renderSubMessage = () => {
		const { config, onEditMap, selectedApp, isHasIntroBot, isTarget } =
			this.props;
		const { messages, id, mode } = config;
		const { onChangeDraftUploadMedia } = this.props;
		const hasButtonMessage = this.hasButtonMessage();
		const hasMoreTwoMessage = this.hasMoreTwoMessage();

		const searchString = "END";
		const indexOfEND = id.indexOf(searchString);

		if (id === nodeId.START) {
			return (
				<Start isHasIntroBot={isHasIntroBot} mode={mode} isTarget={isTarget} />
			);
		} else if (indexOfEND !== -1) {
			return <End isTarget={isTarget} />;
		}

		return messages.map((m, index) => {
			const isLast = messages.length - 1 === index;
			switch (m.mode) {
				case "MESSAGE":
					return (
						<div className={styles.box} key={index}>
							{this.renderTimeline(index + 1, isLast)}
							<Message
								mainMessage={config}
								isLast={isLast}
								index={index}
								isEnd={true}
								isOnlyOne={this.isOnlyOne()}
								onAddNewMessage={this.onAddNewMessage}
								onCopyMessage={this.onCopyMessage}
								onDeleteMessage={this.onDeleteMessage}
								onMessageChange={this.onMessageChange}
								message={m.message}
								isTarget={isTarget}
							/>
							{!m.message &&
								this.renderTypeSelection(
									m.mode,
									index,
									hasButtonMessage,
									hasMoreTwoMessage
								)}
						</div>
					);
				case "MEDIA":
					return (
						<div className={styles.box} key={index}>
							{this.renderTimeline(index + 1, isLast)}
							<Media
								isLast={isLast}
								index={index}
								isOnlyOne={this.isOnlyOne()}
								onAddNewMessage={this.onAddNewMessage}
								onCopyMessage={this.onCopyMessage}
								onDeleteMessage={this.onDeleteMessage}
								image={m.image}
								message={m}
								onChangeDraftUploadMedia={onChangeDraftUploadMedia}
								isTarget={isTarget}
								nodeId={id}
							/>
							{!m.image &&
								this.renderTypeSelection(
									m.mode,
									index,
									hasButtonMessage,
									hasMoreTwoMessage
								)}
						</div>
					);
				case "AUDIO":
					return (
						<div className={styles.box} key={index}>
							{this.renderTimeline(index + 1, isLast)}
							<Audio
								isLast={messages.length - 1 === index}
								index={index}
								isOnlyOne={this.isOnlyOne()}
								onAddNewMessage={this.onAddNewMessage}
								onCopyMessage={this.onCopyMessage}
								onDeleteMessage={this.onDeleteMessage}
								onChangeDraftUploadMedia={onChangeDraftUploadMedia}
								audio={m.audio}
								message={m}
								isTarget={isTarget}
								nodeId={id}
							/>
							{!m.audio &&
								this.renderTypeSelection(
									m.mode,
									index,
									hasButtonMessage,
									hasMoreTwoMessage
								)}
						</div>
					);
				case "VIDEO":
					return (
						<div className={styles.box} key={index}>
							{this.renderTimeline(index + 1, isLast)}
							<Video
								isLast={isLast}
								index={index}
								isOnlyOne={this.isOnlyOne()}
								onAddNewMessage={this.onAddNewMessage}
								onCopyMessage={this.onCopyMessage}
								onDeleteMessage={this.onDeleteMessage}
								onChangeDraftUploadMedia={onChangeDraftUploadMedia}
								video={m.video}
								message={m}
								isTarget={isTarget}
								nodeId={id}
							/>
							{!m.video &&
								this.renderTypeSelection(
									m.mode,
									index,
									hasButtonMessage,
									hasMoreTwoMessage
								)}
						</div>
					);
				case "MAP":
					return (
						<div className={styles.box} key={index}>
							{this.renderTimeline(index + 1, isLast)}
							<Map
								isLast={isLast}
								index={index}
								isOnlyOne={this.isOnlyOne()}
								onAddNewMessage={this.onAddNewMessage}
								onCopyMessage={this.onCopyMessage}
								onDeleteMessage={this.onDeleteMessage}
								onMessageChange={this.onMessageChange}
								location={m.location}
								onEditMap={onEditMap}
								onTitleChange={this.onTitleChange}
								title={m.location.title}
								message={m}
								isTarget={isTarget}
								nodeId={id}
							/>
							{!m.location.name &&
								this.renderTypeSelection(
									m.mode,
									index,
									hasButtonMessage,
									hasMoreTwoMessage
								)}
						</div>
					);
				case "BUTTON":
					return (
						<div className={styles.box} key={index}>
							{this.renderTimeline(index + 1, isLast)}
							<Message
								mainMessage={config}
								index={index}
								isOnlyOne={this.isOnlyOne()}
								isEnd={false}
								onAddNewMessage={this.onAddNewMessage}
								onCopyMessage={this.onCopyMessage}
								onDeleteMessage={this.onDeleteButtons}
								onMessageChange={this.onQuestionChange}
								message={m.question}
								hideDupButton={true}
								isTarget={isTarget}
							/>
							<Button
								items={m.input.options}
								isLast={messages.length - 1 === index}
								index={index}
								isOnlyOne={this.isOnlyOne()}
								onDeleteButtonItem={this.onDeleteButtonItem}
								onCopyButtonItem={this.onCopyButtonItem}
								onAddButtonItem={this.onAddButtonItem}
								onButtonItemMessageChange={this.onButtonItemMessageChange}
								selectedApp={selectedApp}
								isTarget={isTarget}
							/>
						</div>
					);
				default:
					return <div />;
			}
		});
	};

	headerDetail = (id, mode) => {
		const searchString = "END";
		const indexOfEND = id.indexOf(searchString);

		if (id === nodeId.START) {
			return {
				image: "/images/message-icons/start-up.svg",
				label: "Starting point",
			};
		} else if (indexOfEND !== -1) {
			return {
				image: "/images/message-icons/finish.svg",
				label: "Ending point",
			};
		} else if (mode === "BUTTON") {
			return {
				image: "/images/message-icons/button.svg",
				label: "Buttons",
			};
		} else {
			return {
				image: "/images/message-icons/messenger.svg",
				label: "Messages",
			};
		}
	};

	hasButton = () => {
		const {
			config: { messages },
		} = this.props;
		if (messages.length === 0) return false;

		return messages[messages.length - 1].mode === "BUTTON";
	};

	render() {
		const { config, currentAppPlan, t } = this.props;
		const { messages, id } = config;
		const hasButton = this.hasButton();
		const headerDetail = this.headerDetail(
			this.state.id,
			hasButton ? "BUTTON" : null
		);

		const searchString = "END";
		const indexOfEND = id.indexOf(searchString);

		const proPlan = currentAppPlan.pro.some((item) =>
			messages.some((obj) => item === config.mode + "/" + obj.mode)
		);
		const starterPlan = currentAppPlan.starter.some((item) =>
			messages.some((obj) => item === config.mode + "/" + obj.mode)
		);

		return (
			<div>
				<div
					className={`${id === nodeId.START
						? "union-message-block-header"
						: indexOfEND !== -1
							? "union-message-block-end"
							: "union-message-block"
						}`}
				>
					<div className="header">
						{proPlan && <div className="plan-tag">PRO</div>}
						{starterPlan && <div className="plan-tag">STARTER</div>}
						{id !== nodeId.START && indexOfEND === -1 && (
							<div className="message-menu">
								<div className="message-menu-body">
									{id !== nodeId.START && id !== "END" && (
										<h6 className="message-id">{id}</h6>
									)}
									<button
										className="transparent"
										onClick={() => this.props.copyToClipBoard(this.state.id)}
									>
										<CopyIcon />
									</button>
									<button
										className="transparent"
										onClick={() => this.props.copyComponentNode(this.state.id)}
									>
										<DupSvg />
									</button>
									{this.state.id !== nodeId.START && (
										<button
											className="transparent"
											onClick={() =>
												this.props.removeComponentNode(this.state.id)
											}
										>
											<TrashSvg />
										</button>
									)}
								</div>
							</div>
						)}
						{indexOfEND !== -1 && (
							<div className="message-menu">
								<div className="message-menu-body">
									{this.state.id !== nodeId.START && (
										<button
											className="transparent"
											onClick={() =>
												this.props.removeComponentNode(this.state.id)
											}
										>
											<TrashSvg />
										</button>
									)}
								</div>
							</div>
						)}
						<div className="icon">
							<img
								style={{ width: "25px" }}
								src={headerDetail.image}
								alt="bot management"
							/>
						</div>
						<div className="detail">
							<div className="header-and-messageId">
								<h6
									style={{
										marginLeft: "12px",
										margin: "8px 12px",
									}}
								>
									{t(`${headerDetail.label}`)}
								</h6>
								{/* {id !== nodeId.START && id !== "END" &&
									<h6 style={{ marginRight: 0, }}>{id}
									</h6>
								} */}
							</div>
							{(id === nodeId.START || indexOfEND !== -1) && (
								<p contentEditable="false">{config.helptext}</p>
							)}
							{id !== nodeId.START && indexOfEND === -1 && (
								<p
									className="nodrag"
									contentEditable="true"
									suppressContentEditableWarning={true}
									onInput={(e) => {
										this.setState({
											helptext: e.currentTarget.textContent,
										});
									}}
									onBlur={() => {
										this.props.onUpdate({
											helptext: this.state.helptext,
										});
									}}
								>
									{config.helptext ||
										config.question ||
										t(`${constants.defaultHelptext}`)}
								</p>
							)}
						</div>
						{!!hasButton && (
							<div className="option-container">
								<button
									className="option"
									onClick={() => {
										const data = cloneDeep({
											...messages[messages.length - 1],
											mode: "UNION-MESSAGE/BUTTON",
											id: this.state.id,
										});
										this.props.onEditMessage(data);
									}}
								>
									<ThreeDot />
								</button>
							</div>
						)}
					</div>
					{<div className="content">{this.renderSubMessage()}</div>}
					{!hasButton && id !== nodeId.START && indexOfEND === -1 && (
						<button
							type="button"
							className="btn btn-default"
							onClick={() => {
								const {
									config: { messages },
								} = this.props;
								this.onAddNewMessage(messages.length - 1);
							}}
							style={{ color: "rgb(69, 73, 110)" }}
						>
							<i className="fa fa-plus" style={{ marginRight: 6 }}></i>
							ADD NEW MESSAGE
						</button>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	selectedApp: state.system.selectedApp,
	currentAppPlan: state.subscription.currentAppPlan,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	onChangeDraftUploadMedia: (draftUploadMedia) => {
		dispatch(actions.draftUploadMedia(draftUploadMedia));
	},
	onEditMessage: (newMessage) => {
		dispatch(actions.editMessage(newMessage));
	},
	onEditMap: (data) => {
		dispatch(actions.onEditMap(data));
	},
});

const Container = connect(mapStateToProps, mapDispatchToProps)(UnionMessage);

export default React.memo(Container);
