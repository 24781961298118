import * as React from "react";
import DupSvg from "../../../../assets/svg/Duplicate";
import EditSvg from "../../../../assets/svg/Edit";
import HandleSource from "../system/handle/handleSource";
import Map from "../../../map2";
import PlusSvg from "../../../../assets/svg/Plus";
import TrashSvg from "../../../../assets/svg/Trash";
import styles from "./union-message.module.scss";
import { Position } from "@xyflow/react";
import { connect } from "react-redux";
import { handleConnectableMax, handlePortId } from "../../config";

class MapMessage extends React.Component {
	render = () => {
		const {
			isLast,
			location,
			isOnlyOne,
			index,
			onAddNewMessage,
			onDeleteMessage,
			onCopyMessage,
			onEditMap,
			credentials,
			onTitleChange,
			title,
			selectedApp,
			message,
			isTarget,
			nodeId,
		} = this.props;
		return (
			<div className="sub-message-container">
				<div className="message" style={{ display: "flex", padding: 0 }}>
					<div
						className="map-preview node"
						data-nodeid="NONE"
						style={{ width: "100%", height: "100px" }}
					>
						<Map
							googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${credentials.map.apiKey}`}
							loadingElement={<div style={{ height: `100%` }} />}
							containerElement={<div style={{ height: `100px` }} />}
							mapElement={<div style={{ height: `100%` }} />}
							center={
								location || {
									lat: Number(process.env.REACT_APP_DEFAULT_LOCATION_LAT),
									lng: Number(process.env.REACT_APP_DEFAULT_LOCATION_LNG),
								}
							}
						/>
					</div>
					<div className="edit-container">
						<button
							onClick={() => {
								onEditMap({
									nodeId: nodeId,
									messageIndex: index,
									isRequiredName: true,
								});
							}}
						>
							<EditSvg />
						</button>
					</div>
					{location.name && (
						<div className="detail-maps">
							<p>{location.name}</p>
						</div>
					)}
					{!!isLast && (
						<div className="piece-end-point">
							<HandleSource
								id={handlePortId.right}
								position={Position.Right}
								isTarget={isTarget}
								isConnectable={handleConnectableMax.medium}
							/>
							<div className="piece-end-point__drag-helper">
								<img
									alt=""
									className="piece-end-point__drag-helper-img"
									src="/images/help-arrow.png"
								/>
							</div>
						</div>
					)}
					<div className="message-menu">
						<div className="message-menu-body">
							<button
								className="transparent"
								onClick={() => onCopyMessage(index)}
							>
								<DupSvg />
							</button>
							{!isOnlyOne && (
								<button
									className="transparent"
									onClick={() => onDeleteMessage(index)}
								>
									<TrashSvg />
								</button>
							)}
							<button
								className="transparent"
								onClick={() => onAddNewMessage(index)}
							>
								<PlusSvg />
							</button>
						</div>
					</div>
				</div>
				<div
					className={styles.mapTitle}
					style={{ display: selectedApp.platform === "line" ? "" : "none" }}
				>
					<input
						value={title}
						onChange={(event) => onTitleChange(index, event.target.value)}
						placeholder="Please enter a description"
					/>
				</div>
			</div>
		);
	};
}

const mapStateToProps = (state) => ({
	credentials: state.system.credentials,
	selectedApp: state.system.selectedApp,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

const Container = connect(mapStateToProps, mapDispatchToProps)(MapMessage);

export default React.memo(Container);
