import * as React from "react";
import DupSvg from "../../../../assets/svg/Duplicate";
import EditSvg from "../../../../assets/svg/Edit";
import HandleSource from "../system/handle/handleSource";
import PlusSvg from "../../../../assets/svg/Plus";
import TrashSvg from "../../../../assets/svg/Trash";
import { Position } from "@xyflow/react";
import { handleConnectableMax, handlePortId } from "../../config";

class Audio extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...props.config,
		};
	}

	componentDidMount = () => {
		this.refs.audio.pause();
		this.refs.audio.load();
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.audio !== prevProps.message.audio) {
			this.refs?.audio?.pause();
			this.refs?.audio?.load();
		}
	}

	render = () => {
		const {
			isLast,
			audio,
			isOnlyOne,
			index,
			onAddNewMessage,
			onDeleteMessage,
			onCopyMessage,
			onChangeDraftUploadMedia,
			message,
			isTarget,
			nodeId,
		} = this.props;

		return (
			<div className="sub-message-container">
				<div className="media">
					<audio
						controls
						className="player node"
						preload="false"
						ref="audio"
						data-nodeid="NONE"
					>
						<source src={audio} />
					</audio>
					<div className="edit-container">
						<button
							onClick={() => {
								onChangeDraftUploadMedia({
									nodeId: nodeId,
									messageIndex: index,
									type: "FILE_UPLOAD",
									fileType: "AUDIO",
									accept: "audio/*",
								});
							}}
						>
							<EditSvg />
						</button>
					</div>
					{!!isLast && (
						<div className="piece-end-point">
							<HandleSource
								id={handlePortId.right}
								position={Position.Right}
								isTarget={isTarget}
								isConnectable={handleConnectableMax.medium}
							/>
							<div className="piece-end-point__drag-helper">
								<img
									alt=""
									className="piece-end-point__drag-helper-img"
									src="/images/help-arrow.png"
								/>
							</div>
						</div>
					)}
					<div className="message-menu">
						<div className="message-menu-body">
							<button
								className="transparent"
								onClick={() => onCopyMessage(index)}
							>
								<DupSvg />
							</button>
							{!isOnlyOne && (
								<button
									className="transparent"
									onClick={() => onDeleteMessage(index)}
								>
									<TrashSvg />
								</button>
							)}
							<button
								className="transparent"
								onClick={() => onAddNewMessage(index)}
							>
								<PlusSvg />
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	};
}

export default React.memo(Audio);
