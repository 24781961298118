import { useStoreDiagram } from "..";
import { DURATION_VIEW_PORT, nodeId, nodeProps } from "../../config";
import { getToolDiagram } from "../global/diagram";

export const createDraftNode = (set, get, position) => {
    try {
        const newMessage = {
            ...nodeProps,
            mode: "MESSAGE-SELECTION",
            id: nodeId.DRAFT,
            position: {
                ...position,
            },
        };

        useStoreDiagram.getState().addNewNodeInterface([newMessage]);

        setTimeout(() => {
            const changeViewport = () => {
                const node = getToolDiagram("getNode")(nodeId.DRAFT);
                if (node) {
                    const zoom = 1.2;
                    const x =
                        -node.position.x -
                        250 * zoom +
                        window.innerWidth / 2 -
                        (node.measured?.width / 2) * zoom;
                    const y =
                        -node.position.y * zoom +
                        window.innerHeight / 2 -
                        (node.measured?.height / 2) * zoom;

                    getToolDiagram("setViewport")(
                        { x, y, zoom: zoom },
                        { duration: DURATION_VIEW_PORT }
                    );
                }
            };

            changeViewport();
        }, 1);
    } catch (err) {
        console.error("[createDraftNode] ", err);
    }
};
