import * as actions from "../../../../redux/actions";
import { useStoreDiagram } from "..";
import { store } from "../../../../redux";
import { INTERFACE_MESSAGE } from "../../../../utils/config";
import { groupStyle, nodeModes, nodeProps } from "../../config";
import { getMessageConfigDiagram } from "../global/diagram";

export const addNewNode = (set, get, mode, position) => {
    try {
        const messages = getMessageConfigDiagram();

        /** If already have intro, do not add a new one */
        if (mode === "INTRO" && messages["INTRO"]) {
            return;
        }

        const newMessage = {
            ...INTERFACE_MESSAGE(mode),
            ...nodeProps,
            position: {
                x: position.x,
                y: position.y,
            },
        };

        if (newMessage.mode === "UNION-MESSAGE") {
            useStoreDiagram.getState().addNewNodeInterface([newMessage]);
        } else if (newMessage.mode === nodeModes.DIAGRAM_GROUP) {
            newMessage.style = {
                // width: groupStyle.width,
                // height: groupStyle.height,
                zIndex: -1,
            };
            newMessage.width = groupStyle.width;
            newMessage.height = groupStyle.height;

            useStoreDiagram.getState().addNewNodeInterface([newMessage]);
        } else {
            store.dispatch(actions.onSelectNode(newMessage));
        }
    } catch (err) {
        console.error("[addNewNode] ", err);
    }
};
