import * as actions from '../actions'
import * as api from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import CryptoJS from 'crypto-js'
import _ from 'lodash'
import bindWithChunking from '../../utils/pusher'
import eventlog from '../../modules/eventlog'
import { store } from '..'

function* onSelectMessengerVisitor() {
	while (true) {
		const {
			payload: { visitor, appId },
		} = yield take(types.ON_SELECT_MESSENGER_VISITOR)
		try {
			const messengerManagers = yield select(selectors.getMessengerManager)

			const apps = yield select(selectors.getApps)
			const appSlug = apps.find((app) => app._id === appId).slug

			const messengerManager = messengerManagers[appId]

			yield put(actions.visitorMessages({}))

			// set read
			yield put(actions.selectedVisitor(visitor))
			yield put(actions.isLoadingMessages(true))

			const currentMessages = yield select(selectors.getMessengerMessages)
			const { data } = yield call(api.fetctChatMessages, appSlug, visitor._id)

			const messages = data || []
			yield put(
				actions.visitorMessages({
					...currentMessages,
					[visitor._id]: messages,
				})
			)

			yield put(actions.hasMoreMessages(visitor.messages_number !== messages.length))
			var channel = messengerManager.subscribe(visitor._id)
			bindWithChunking(channel, 'new-message', function (responseData) {
				var message = CryptoJS.AES.decrypt(responseData, visitor._id).toString()
				message = JSON.parse(CryptoJS.enc.Hex.parse(message).toString(CryptoJS.enc.Utf8))
				const currentMessages = selectors.getMessengerMessages(store.getState())
				if (_.get(currentMessages, `${visitor._id}[0]._id`) !== message._id) {
					store.dispatch(
						actions.visitorMessages({
							...currentMessages,
							[visitor._id]: [message, ...(currentMessages[visitor._id] || [])],
						})
					)
				}
			})

			yield put(actions.isLoadingMessages(false))
		} catch (err) {
			console.error('[onSelectMessengerVisitor] ', err)
			yield put(actions.isLoadingMessages(false))
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			eventlog.error('select messenger visitor', {
				message: err.message
			})
		}
	}
}

export default onSelectMessengerVisitor
