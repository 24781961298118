import * as types from "../types";

const system = (
	state = {
		apps: [],
		editModalConfig: {},
		selectedApp: {},
		draftAppConfig: {},
		templateConfig: [],
		isSaveConfigLoading: false,
		isPublishingApp: false,
		draftNewBot: {
			page: 1,
			icon: "",
			app: {
				name: "",
				icon: "",
				description: "",
				slug: "",
				platform: "web",
			},
			dirty: {},
			errors: {},
		},
		isBotManagementLoading: true,
		isShowNodeMenu: true,
		draftUploadMedia: {
			type: "FILE_UPLOAD",
			url: null,
			uploading: false,
			messageId: null,
			nodeId: null,
		},
		startOver: {},
		credentials: {
			recaptcha: {
				siteKey: "",
			},
		},
		sharedToken: "",
		googleSpreadsheets: [],
		typingEmulation: {},
		cancelToken: {},
		mobileApp: {},
		mobileAppConfig: {},
		mobileAppBuilds: [],
		isFetchingLineConfig: false,
		lineConfig: {},
		lineConfigErrors: {},
		enablingFeatureFlags: {},
		featureFlags: [],
		redirectUrl: null,
		ecommerceConfig: {},
		responses: [],
		userID: "",
		uniqueID: "",
	},
	action
) => {
	switch (action.type) {
		case types.APPS:
			return {
				...state,
				apps: action.payload.apps,
			};
		case types.EDIT_MODAL_CONFIG:
			return {
				...state,
				editModalConfig: action.payload.editModalConfig,
			};
		case types.ECOMMERCE_CONFIG:
			return {
				...state,
				ecommerceConfig: action.payload.config,
			};
		case types.IS_FETCHING_LINE_CONFIG:
			return {
				...state,
				isFetchingLineConfig: action.payload.status,
			};
		case types.LINE_CONFIG:
			return {
				...state,
				lineConfig: action.payload.config,
			};
		case types.SELECTED_APP:
			return {
				...state,
				selectedApp: action.payload.app,
			};
		case types.PARAMS:
			return {
				...state,
				params: action.payload.params,
			};
		case types.DRAFT_APP_CONFIG:
			return {
				...state,
				draftAppConfig: action.payload.app,
			};
		case types.TEMPLATE_CONFIG:
			return {
				...state,
				templateConfig: action.payload.templates,
			};
		case types.UPDATE_SAVE_CONFIG_LOADING:
			return {
				...state,
				isSaveConfigLoading: action.payload.status,
			};
		case types.START_OVER:
			return {
				...state,
				startOver: action.payload.config,
			};
		case types.RESPONSES:
			return {
				...state,
				responses: action.payload.responses,
			};
		case types.IS_RESPONSES_LOADING:
			return {
				...state,
				isResponsesLoading: action.payload.status,
			};
		case types.IS_CONFIG_LOADING:
			return {
				...state,
				isConfigLoading: action.payload.status,
			};
		case types.IS_APPS_LOADING:
			return {
				...state,
				isAppsLoading: action.payload.status,
			};
		case types.IS_BOT_MANAGEMENT_LOADING:
			return {
				...state,
				isBotManagementLoading: action.payload.status,
			};
		case types.IS_SHOW_CREATE_NEW_BOT_MODAL:
			return {
				...state,
				isShowCreateNewBotModal: action.payload.isShow,
			};
		case types.TOAST:
			return {
				...state,
				toast: action.payload.toast,
			};
		case types.DRAFT_NEW_BOT:
			return {
				...state,
				draftNewBot: action.payload.data,
			};
		case types.TOGGLE_NODE_MENU:
			return {
				...state,
				isShowNodeMenu: !state.isShowNodeMenu,
			};
		case types.IS_SAVING:
			return {
				...state,
				isSaving: action.payload.isSaving,
			};
		case types.DRAFT_UPLOAD_MEDIA:
			return {
				...state,
				draftUploadMedia: {
					...action.payload.draftUploadMedia,
				} || {
					type: "FILE_UPLOAD",
					url: null,
					uploading: false,
					messageId: null,
					nodeId: null,
				},
			};
		case types.IS_DELETING_APP:
			return {
				...state,
				isDeletingApp: action.payload.isDeleting,
			};
		case types.IS_COPYING_APP:
			return {
				...state,
				isCopyingApp: action.payload.isCopying,
			};
		case types.CREDENTIALS:
			return {
				...state,
				credentials: action.payload.credentials,
			};
		case types.SHARED_TOKEN:
			return {
				...state,
				sharedToken: action.payload.token,
			};
		case types.IS_DISTRIBUTE_LOADING:
			return {
				...state,
				isDistributeLoading: action.payload.status,
			};
		case types.GOOGLE_LINKING_URL:
			return {
				...state,
				googleLinkingUrl: action.payload.url,
			};
		case types.GOOGLE_LINKING_DETAIL:
			return {
				...state,
				googleLinkingDetail: action.payload.detail,
			};
		case types.GOOGLE_SPREADSHEETS:
			return {
				...state,
				googleSpreadsheets: action.payload.spreadsheets,
			};
		case types.TYPING_EMULATION:
			return {
				...state,
				typingEmulation: action.payload.config,
			};
		case types.IS_VALIDATING_SLUG:
			return {
				...state,
				isValidatingSlug: action.payload.status,
			};
		case types.MOBILE_APP:
			return {
				...state,
				mobileApp: action.payload.config,
			};
		case types.MOBILE_APP_CONFIG:
			return {
				...state,
				mobileAppConfig: action.payload.config,
			};
		case types.IS_SAVING_MOBILE_APP_CONFIG:
			return {
				...state,
				isSavingMobileAppConfig: action.payload.status,
			};
		case types.IS_GENERATING_MOBILE_APP_CONFIG:
			return {
				...state,
				isGeneratingMobileAppConfig: action.payload.status,
			};
		case types.MOBILE_APP_BUILDS:
			return {
				...state,
				mobileAppBuilds: action.payload.mobileAppBuilds,
			};
		case types.IS_PUBLISHING_APP:
			return {
				...state,
				isPublishingApp: action.payload.status,
			};
		case types.LINE_CONFIG_ERRORS:
			return {
				...state,
				lineConfigErrors: action.payload.errors,
			};
		case types.ENABLING_FEATURE_FLAG:
			return {
				...state,
				enablingFeatureFlags: action.payload.flags,
			};
		case types.FEATURE_FLAGS:
			return {
				...state,
				featureFlags: action.payload.featureFlags,
			};
		case types.REDIRECT:
			return {
				...state,
				redirectUrl: action.payload.url,
			};
		case types.STORE_USER_ID:
			return {
				...state,
				userID: action.payload.userID,
			};
		case types.STORE_UNIQUE_ID:
			return {
				...state,
				uniqueID: action.payload.uniqueID,
			};
		default:
			return state;
	}
};

export default system;
