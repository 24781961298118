import * as actions from '../actions'
import * as apis from '../apis'
import * as selectors from '../selectors'
import * as types from '../types'

import { call, put, select, take } from 'redux-saga/effects'

import CryptoJS from 'crypto-js'
import Pusher from 'pusher-js'
import _ from 'lodash'
import bindWithChunking from '../../utils/pusher'
import eventlog from '../../modules/eventlog'
import { featureFlags as featureFlagsConst } from '../../constants'
import { store } from '../index'

const getLastMessage = (message) => {
	const isOwnerMessage = message.user._id !== 1
	let text = message.text
	if (message.video) {
		text = (isOwnerMessage ? 'You' : 'User') + ' sent the video'
	} else if (message.direction) {
		text = (isOwnerMessage ? 'You' : 'User') + ' sent the map direction'
	} else if (message.svg) {
		text = (isOwnerMessage ? 'You' : 'User') + ' sent the image'
	} else if (_.get(message, 'question.mode') === 'APPROVE') {
		text = (isOwnerMessage ? 'You' : 'User') + ' sent the agreement'
	} else if (message.location) {
		text = (isOwnerMessage ? 'You' : 'User') + ' sent the location'
	} else if (message.audio) {
		text = (isOwnerMessage ? 'You' : 'User') + ' sent the audio'
	} else if (message.image) {
		text = (isOwnerMessage ? 'You' : 'User') + ' sent the image'
	}

	return (isOwnerMessage ? 'You: ' : '') + text
}

function subscribePresence(pusher, visitorId) {
	var channel = pusher.subscribe(`presence-${visitorId}`)

	channel.bind('pusher:subscription_succeeded', function (members) {
		const presenceVisitors = selectors.getPresenceVisitors(store.getState())
		const onlineVisitors = Object.keys(members.members).reduce((o, key) => {
			return {
				...o,
				[key]: true,
			}
		}, {})
		store.dispatch(
			actions.presenceVisitors({
				...presenceVisitors,
				...onlineVisitors,
			})
		)
	})

	channel.bind('pusher:member_added', function (visitor) {
		const presenceVisitors = selectors.getPresenceVisitors(store.getState())
		store.dispatch(
			actions.presenceVisitors({
				...presenceVisitors,
				[visitor.id]: true,
			})
		)
	})

	channel.bind('pusher:member_removed', function (visitor) {
		const presenceVisitors = selectors.getPresenceVisitors(store.getState())
		delete presenceVisitors[visitor.id]
		store.dispatch(
			actions.presenceVisitors({
				...presenceVisitors,
			})
		)
	})
}

function subscribeMessenger(credentials, app, messengerManagers) {
	var pusher = new Pusher(credentials.pusher.key, {
		cluster: credentials.pusher.cluster,
		forceTLS: true,
		channelAuthorization: {
			params: {
				id: app._id,
			},
			headers: {
				Authorization: `Bearer ${apis.getToken()}`,
			},
			endpoint: apis.pusherAuth(),
		},
	})

	messengerManagers[app._id] = pusher
	store.dispatch(actions.messengerManager(messengerManagers))

	const channel = pusher.subscribe(app._id)
	// eslint-disable-next-line
	bindWithChunking(channel, 'room-updated', function (responseData) {
		var visitor = CryptoJS.AES.decrypt(responseData, app._id).toString()
		visitor = JSON.parse(CryptoJS.enc.Hex.parse(visitor).toString(CryptoJS.enc.Utf8))

		const visitors = selectors.getVisitors(store.getState())
		const messengerManagers = selectors.getMessengerManager(store.getState())
		const messengerManager = messengerManagers[app._id]

		if (!messengerManager.channels.channels[`presence-${visitor._id}`]) {
			subscribePresence(pusher, visitor._id)
		}

		store.dispatch(
			actions.visitors({
				...visitors,
				[visitor._id]: {
					...visitor,
					lastMessage: getLastMessage(visitor.last_message),
				},
			})
		)
	})

	// eslint-disable-next-line
	bindWithChunking(channel, 'human-takeover', function (responseData) {
		var data = CryptoJS.AES.decrypt(responseData, app._id).toString()
		data = JSON.parse(CryptoJS.enc.Hex.parse(data).toString(CryptoJS.enc.Utf8))

		const visitors = selectors.getVisitors(store.getState())
		const visitor = visitors[data.room_id]
		store.dispatch(
			actions.visitors({
				...visitors,
				[data.room_id]: {
					...visitor,
					human_takeover_enabled: data.status,
				},
			})
		)
	})

	bindWithChunking(channel, 'human-takeover-queues', function (responseData) {
		var data = CryptoJS.AES.decrypt(responseData, app._id).toString()
		data = JSON.parse(CryptoJS.enc.Hex.parse(data).toString(CryptoJS.enc.Utf8))

		const humantakeover = selectors.getHumantakeover(store.getState())

		humantakeover[app._id] = {
			count: data.count
		}

		store.dispatch(
			actions.humantakeover({
				...humantakeover
			})
		)
	})
}

export function* startAppChat() {
	while (true) {
		const { payload: { query } } = yield take(types.START_APP_CHAT)
		try {
			const credentials = yield select(selectors.getCredentials)

			yield put(actions.isLoadingVisitors(true))
			const selectedApp = yield select(selectors.getSelectedApp)
			const featureFlags = yield select(selectors.getFeatureFlags)

			if (!featureFlags.includes(featureFlagsConst.chat)) {
				console.info('Chat subscription is not enabled')
				yield put(actions.isLoadingVisitors(false))
				continue
			}

			const { data } = yield call(apis.fetchAppVisitors, query)
			const visitors = data ? data.rooms : {}
			const hasNextRoom = data ? data.has_next : false
			yield put(actions.hasMoreVisitors(hasNextRoom))

			const humantakeover = yield select(selectors.getHumantakeover)
			humantakeover[selectedApp._id] = {
				count: data.count
			}

			yield put(actions.humantakeover({
				...humantakeover,
				...data.humantakeover
			}))

			let messengerManagers = {}
			subscribeMessenger(credentials, selectedApp, messengerManagers)

			// eslint-disable-next-line
			const visitorsWithLastMessage = (visitors || []).reduce((o, visitor) => {
				// // checking presence here to reduce loops
				subscribePresence(messengerManagers[selectedApp._id], visitor._id)

				return {
					...o,
					[visitor._id]: {
						...visitor,
						lastMessage: getLastMessage(visitor.last_message),
					},
				}
			}, {})

			store.dispatch(actions.visitors(visitorsWithLastMessage))
			yield put(actions.isLoadingVisitors(false))
		} catch (err) {
			console.error('[startAppChat] ', err)
			yield put(actions.isLoadingVisitors(false))
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			eventlog.error('start app chat failed', {
				message: err.message
			})
		}
	}
}

export function* startIntegrationChat() {
	while (true) {
		const { payload: { query } } = yield take(types.START_INTEGRATION_CHAT)
		try {
			const credentials = yield select(selectors.getCredentials)

			yield put(actions.isLoadingVisitors(true))

			// since this is not depending on fetch apps/design function so this needs to be loaded individually
			let apps = yield select(selectors.getApps)
			if (apps.length === 0) {
				const response = yield call(apis.fetchApps)
				yield put(actions.apps(response.data))
				apps = response.data
			}

			// random design that will use to show as background chat
			const random = Math.floor(Math.random() * apps.length)
			const { data: design } = yield call(apis.designConfig, apps[random].slug)
			yield put(actions.design(design))

			const { data } = yield call(apis.fetchIntegrationVisitors, query)
			const visitors = data ? data.rooms : {}
			const hasNextRoom = data ? data.has_next : false
			yield put(actions.hasMoreVisitors(hasNextRoom))

			const humantakeover = yield select(selectors.getHumantakeover)
			yield put(actions.humantakeover({
				...humantakeover,
				...data.humantakeover
			}))

			let messengerManagers = {}
			apps.forEach((app) => {
				subscribeMessenger(credentials, app, messengerManagers)
			})

			// eslint-disable-next-line
			const visitorsWithLastMessage = (visitors || []).reduce((o, visitor) => {
				// // checking presence here to reduce loops
				subscribePresence(messengerManagers[visitor.app_id], visitor._id)

				return {
					...o,
					[visitor._id]: {
						...visitor,
						lastMessage: getLastMessage(visitor.last_message),
					},
				}
			}, {})

			store.dispatch(actions.visitors(visitorsWithLastMessage))
			yield put(actions.isLoadingVisitors(false))
		} catch (err) {
			console.error('[startIntegrationChat] ', err)
			yield put(actions.isLoadingVisitors(false))
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			eventlog.error('start integration chat failed', {
				message: err.message
			})
		}
	}
}

export function* onLoadMoreIntegrationVisitors() {
	while (true) {
		const { payload: { fromId, query } } = yield take(types.ON_LOAD_MORE_INTEGRATION_VISITORS)
		try {
			const { data } = yield call(apis.fetchIntegrationVisitors, query, fromId)
			const newVisitors = data ? data.rooms : {}
			const hasNextRoom = data ? data.has_next : false

			yield put(actions.hasMoreVisitors(hasNextRoom))

			const visitors = yield select(selectors.getVisitors)
			const pusher = yield select(selectors.getMessengerManager)
			// eslint-disable-next-line
			const visitorsWithLastMessage = (newVisitors || []).reduce((o, visitor) => {
				// checking presence here to reduce loops
				subscribePresence(pusher, visitor._id)

				return {
					...o,
					[visitor._id]: {
						...visitor,
						lastMessage: getLastMessage(visitor.last_message),
					},
				}
			}, visitors)

			yield put(actions.visitors(visitorsWithLastMessage))
		} catch (err) {
			console.error('[onLoadMoreIntegrationVisitors] ', err)
		}
	}
}

export function* onLoadMoreAppVisitors() {
	while (true) {
		const { payload: { fromId, query } } = yield take(types.ON_LOAD_MORE_APP_VISITORS)
		try {
			const { data } = yield call(apis.fetchAppVisitors, query, fromId)
			const newVisitors = data ? data.rooms : {}
			const hasNextRoom = data ? data.has_next : false

			yield put(actions.hasMoreVisitors(hasNextRoom))

			const visitors = yield select(selectors.getVisitors)
			const messengerManagers = yield select(selectors.getMessengerManager)
			// eslint-disable-next-line
			const visitorsWithLastMessage = (newVisitors || []).reduce((o, visitor) => {
				// checking presence here to reduce loops
				subscribePresence(messengerManagers[visitor.app_id], visitor._id)

				return {
					...o,
					[visitor._id]: {
						...visitor,
						lastMessage: getLastMessage(visitor.last_message),
					},
				}
			}, visitors)

			yield put(actions.visitors(visitorsWithLastMessage))
		} catch (err) {
			console.error('[onLoadMoreAppVisitors] ', err)
		}
	}
}
