import ComponentNode from "./node";
import React, { memo } from "react";
import useDetachNodes from "../feature/dynamicGrouping/useDetachNodes";
import { NodeToolbar, useStore } from "@xyflow/react";
import { useTranslation } from "react-i18next";

function NodeModel(props) {
    const { id } = props;
    const { t } = useTranslation(["board"]);

    const hasParent = useStore(
        (store) => !!store.nodeLookup.get(id)?.parentId
    );

    const detachNodes = useDetachNodes();
    const onDetach = () => detachNodes([id]);

    return (
        <>
            <NodeToolbar className="nodrag">
                {hasParent && <button onClick={onDetach}>{t("group.Detach")}</button>}
            </NodeToolbar>
            <ComponentNode {...props} />
        </>
    );
}

export default memo(NodeModel);
