import * as api from "../apis";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import { call, take } from "redux-saga/effects";

function* acceptInvitation() {
    while (true) {
        const {
            payload: { userID },
        } = yield take(types.ACCEPT_INVITATION);
        try {
            localStorage.setItem("isInvitation", "");
            eventlog.info("accept invitation");
            const query = new URLSearchParams(window.location.search);
            yield call(api.acceptInvitation, query.get("access_token"), userID);
            localStorage.setItem(
                "InvitationMessage",
                "success:Accept invitation success"
            );
            window.location.href = api.APP_HOSTNAME;
        } catch (err) {
            console.error("[acceptInvitation] ", err);
            eventlog.error("accept invitation", {
                message: err.message,
            });

            if (err.message === "Request failed with status code 302") {
                localStorage.setItem("InvitationMessage", "error:User is exist in app");
            } else if (err.message === "Request failed with status code 403") {
                localStorage.setItem("InvitationMessage", "error:App seats exceeded");
            } else {
                localStorage.setItem(
                    "InvitationMessage",
                    "error:Invitation link token have been used or expired"
                );
            }
            window.location.href = api.APP_HOSTNAME;
        }
    }
}

export default acceptInvitation;
