import "./style.scss";
import * as actions from "../../../redux/actions.js";
import ABTestNode from "./componentNodes/abTest.js";
import BusinessHours from "./componentNodes/businessHours.js";
import ButtonTemplate from "./componentNodes/lineButtonTemplate.js";
import CodeNode from "./componentNodes/code.js";
import ConditionNode from "./componentNodes/condition.js";
import ConfirmTemplate from "./componentNodes/lineConfirmTemplate.js";
import DiagramGroup from "./componentNodes/diagramGroup.js";
import EndNode from "./componentNodes/end.js";
import FlexSimulator from "./componentNodes/flexSimulator.js";
import ImageCarouselTemplate from "./componentNodes/lineImageCarouselTemplate.js";
import IntroNode from "./componentNodes/intro.js";
import LineCarouselTemplate from "./componentNodes/lineCarouselTemplate.js";
import MessageSelection from "./category/messageSelection.js";
import OrdersTemplate from "./componentNodes/ordersTemplate.js";
import PipelineNode from "./componentNodes/pipeline.js";
import ProductCarouselTemplate from "./componentNodes/productCarouselTemplate.js";
import QuestionNode from "./componentNodes/question.js";
import React, { memo, useCallback, useEffect } from "react";
import RichMenuNode from "./componentNodes/lineRichMenu.js";
import ScaleNode from "./componentNodes/scale.js";
import Sticker from "./componentNodes/sticker.js";
import UnionMessageNode from "./componentNodes/unionMessage.js";
import YesNoNode from "./componentNodes/yesno.js";
import copyToClipBoardMain from "../../../utils/copyToClipBoard.js";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { contextualZoomStyle, nodeId, nodeModes } from "../config/index.js";
import { useStoreDiagram } from "../store/index.js";

import {
	useConnection,
	useReactFlow,
	useUpdateNodeInternals,
} from "@xyflow/react";

import {
	getMessageConfigDiagram,
	setMessageConfigDiagram,
} from "../store/global/diagram.js";

import {
	advancedMessages,
	messages as messageDetailConfig,
} from "../../../utils/config.js";

// import ImageMapNode from './nodes/lineImageMap'

const getConfig = (id, messages) => {
	if (id === nodeId.DRAFT) {
		return {
			mode: "MESSAGE-SELECTION",
			id: nodeId.DRAFT,
		};
	}

	if (id === nodeId.NONE) {
		return {
			mode: "NONE",
			id: nodeId.NONE,
		};
	}

	return messages[id];
};

function ComponentNode(props) {
	const { t } = useTranslation(["board"]);
	const { id, isHasIntroBot, onSelectNode, isHasIntro } = props;

	const messages = getMessageConfigDiagram();

	const { setNodes, getNode, getNodes, getEdge, getEdges } = useReactFlow();
	const updateNodeInternals = useUpdateNodeInternals();

	const connection = useConnection();
	const isTarget =
		!!connection.fromHandle &&
		connection.fromHandle.nodeId !== id &&
		id !== nodeId.START;

	const message = getConfig(id, messages);

	useEffect(() => {
		// Perform your logic to determine isHasIntro
		const isHasIntroNow = messages
			? Object.keys(messages).find((mode) => mode === "INTRO")
			: false;

		if (isHasIntro !== !!isHasIntroNow) {
			isHasIntroBot(!!isHasIntroNow);
		}
	}, [isHasIntro, isHasIntroBot, messages]);

	const onHandleUpdate = useCallback(
		(config) => {
			const messageNow = {
				...message,
				...config,
			};
			const id = message.id;
			messages[id] = {
				...messageNow,
			};
			setMessageConfigDiagram(messages);
			updateNodeInternals(id);
		},
		[message, messages, updateNodeInternals]
	);

	const copyToClipBoard = useCallback(
		(text) => {
			copyToClipBoardMain(text);
			toast.success(t("keyboardShortcut.CopyNotify"));
		},
		[t]
	);

	const toolDiagram = {
		getNode,
		getNodes,
		getEdge,
		getEdges,
		setNodes,
		messages: messages,
		messageConfig: setMessageConfigDiagram,
		onSelectNode: onSelectNode,
	};

	const {
		selectedApp,
		currentAppPlan,
		removeComponentNode,
		copyComponentNode,
		createNodeByMessageSelection,
	} = props;

	const addHandles = useStoreDiagram((state) => state.addHandles);
	const removeHandles = useStoreDiagram((state) => state.removeHandles);
	const resetHandles = useStoreDiagram((state) => state.resetHandles);

	if (message.mode === "MESSAGE-SELECTION") {
		return (
			<div id={`node-${message.id}`} style={contextualZoomStyle}>
				<MessageSelection
					selectedApp={selectedApp}
					isTarget={isTarget}
					removeComponentNode={removeComponentNode}
					toolDiagram={toolDiagram}
					createNodeByMessageSelection={createNodeByMessageSelection}
				/>
			</div>
		);
	}

	if (message.mode === "NONE") {
		return <div id={`node-${message.id}`}>{message.id}</div>;
	}

	if (message.mode === "UNION-MESSAGE") {
		return (
			<UnionMessageNode
				message={message}
				onHandleUpdate={onHandleUpdate}
				isTarget={isTarget}
				copyComponentNode={copyComponentNode}
				removeComponentNode={removeComponentNode}
				copyToClipBoard={copyToClipBoard}
				removeHandles={removeHandles}
				resetHandles={resetHandles}
				addHandles={addHandles}
			/>
		);
	}

	const detail = messageDetailConfig[message.mode];
	if (message.mode === "YESNO") {
		return (
			<YesNoNode
				message={message}
				copyComponentNode={copyComponentNode}
				removeComponentNode={removeComponentNode}
				copyToClipBoard={copyToClipBoard}
				onSelectNode={onSelectNode}
				detail={detail}
				onHandleUpdate={onHandleUpdate}
				currentAppPlan={currentAppPlan}
				isTarget={isTarget}
			/>
		);
	}

	if (message.mode === "CONDITION") {
		return (
			<ConditionNode
				message={message}
				copyComponentNode={copyComponentNode}
				removeComponentNode={removeComponentNode}
				copyToClipBoard={copyToClipBoard}
				onSelectNode={onSelectNode}
				detail={detail}
				onHandleUpdate={onHandleUpdate}
				currentAppPlan={currentAppPlan}
				isTarget={isTarget}
			/>
		);
	}

	if (message.mode === "CODE") {
		return (
			<CodeNode
				message={message}
				copyComponentNode={copyComponentNode}
				removeComponentNode={removeComponentNode}
				copyToClipBoard={copyToClipBoard}
				onSelectNode={onSelectNode}
				detail={detail}
				onHandleUpdate={onHandleUpdate}
				currentAppPlan={currentAppPlan}
				isTarget={isTarget}
			/>
		);
	}

	if (message.mode === "AB-TEST") {
		return (
			<ABTestNode
				message={message}
				copyComponentNode={copyComponentNode}
				removeComponentNode={removeComponentNode}
				copyToClipBoard={copyToClipBoard}
				onSelectNode={onSelectNode}
				detail={detail}
				onHandleUpdate={onHandleUpdate}
				currentAppPlan={currentAppPlan}
				isTarget={isTarget}
			/>
		);
	}

	if (message.mode === "SCALE") {
		return (
			<ScaleNode
				message={message}
				copyComponentNode={copyComponentNode}
				removeComponentNode={removeComponentNode}
				copyToClipBoard={copyToClipBoard}
				onSelectNode={onSelectNode}
				detail={detail}
				onHandleUpdate={onHandleUpdate}
				currentAppPlan={currentAppPlan}
				isTarget={isTarget}
			/>
		);
	}

	if (message.mode === "LINE/BUTTON-TEMPLATE") {
		return (
			<ButtonTemplate
				message={message}
				copyComponentNode={copyComponentNode}
				removeComponentNode={removeComponentNode}
				copyToClipBoard={copyToClipBoard}
				onSelectNode={onSelectNode}
				detail={detail}
				onHandleUpdate={onHandleUpdate}
				currentAppPlan={currentAppPlan}
				isTarget={isTarget}
			/>
		);
	}

	if (message.mode === "LINE/CONFIRM-TEMPLATE") {
		return (
			<ConfirmTemplate
				message={message}
				copyComponentNode={copyComponentNode}
				removeComponentNode={removeComponentNode}
				copyToClipBoard={copyToClipBoard}
				onSelectNode={onSelectNode}
				detail={detail}
				onHandleUpdate={onHandleUpdate}
				currentAppPlan={currentAppPlan}
				isTarget={isTarget}
			/>
		);
	}

	if (message.mode === "LINE/CAROUSEL-TEMPLATE") {
		return (
			<LineCarouselTemplate
				message={message}
				copyComponentNode={copyComponentNode}
				removeComponentNode={removeComponentNode}
				copyToClipBoard={copyToClipBoard}
				onSelectNode={onSelectNode}
				detail={detail}
				onHandleUpdate={onHandleUpdate}
				currentAppPlan={currentAppPlan}
				isTarget={isTarget}
			/>
		);
	}

	if (message.mode === "LINE/IMAGE-CAROUSEL-TEMPLATE") {
		return (
			<ImageCarouselTemplate
				message={message}
				copyComponentNode={copyComponentNode}
				removeComponentNode={removeComponentNode}
				copyToClipBoard={copyToClipBoard}
				onSelectNode={onSelectNode}
				detail={detail}
				onHandleUpdate={onHandleUpdate}
				currentAppPlan={currentAppPlan}
				isTarget={isTarget}
			/>
		);
	}

	if (message.mode === "PRODUCT-CAROUSEL-TEMPLATE") {
		return (
			<ProductCarouselTemplate
				message={message}
				copyComponentNode={copyComponentNode}
				removeComponentNode={removeComponentNode}
				copyToClipBoard={copyToClipBoard}
				onSelectNode={onSelectNode}
				detail={detail}
				onHandleUpdate={onHandleUpdate}
				currentAppPlan={currentAppPlan}
				isTarget={isTarget}
			/>
		);
	}

	if (message.mode === "INTRO") {
		return (
			<IntroNode
				message={message}
				copyComponentNode={copyComponentNode}
				removeComponentNode={removeComponentNode}
				copyToClipBoard={copyToClipBoard}
				onSelectNode={onSelectNode}
				detail={detail}
				onHandleUpdate={onHandleUpdate}
				currentAppPlan={currentAppPlan}
				isHasIntroBot={isHasIntroBot}
				isTarget={isTarget}
			/>
		);
	}

	if (message.mode.split("/")[0] === "FLEX") {
		return (
			<FlexSimulator
				message={message}
				copyComponentNode={copyComponentNode}
				removeComponentNode={removeComponentNode}
				copyToClipBoard={copyToClipBoard}
				onSelectNode={onSelectNode}
				detail={detail}
				onHandleUpdate={onHandleUpdate}
				currentAppPlan={currentAppPlan}
				isTarget={isTarget}
			/>
		);
	}

	if (message.mode === "LINE/RICH-MENU") {
		return (
			<RichMenuNode
				message={message}
				copyComponentNode={copyComponentNode}
				removeComponentNode={removeComponentNode}
				copyToClipBoard={copyToClipBoard}
				onSelectNode={onSelectNode}
				detail={detail}
				onHandleUpdate={onHandleUpdate}
				currentAppPlan={currentAppPlan}
				isTarget={isTarget}
			/>
		);
	}

	// if (message.mode === 'LINE/IMAGE-MAP') {
	// 	return (
	// 		<ImageMapNode
	// 			message={message}
	// 			copyComponentNode={copyComponentNode}
	// 			removeComponentNode={removeComponentNode}
	// 			copyToClipBoard={copyToClipBoard}
	// 			onSelectNode={onSelectNode}
	// 			detail={detail}
	// 			onHandleUpdate={onHandleUpdate}
	// 			currentAppPlan={currentAppPlan}
	// 			isTarget={isTarget}
	// 		/>
	// 	)
	// }

	if (message.mode === "GOOD-BYE" || message.mode === "JUMP-TO") {
		return (
			<EndNode
				message={message}
				copyComponentNode={copyComponentNode}
				removeComponentNode={removeComponentNode}
				copyToClipBoard={copyToClipBoard}
				onSelectNode={onSelectNode}
				detail={detail}
				onHandleUpdate={onHandleUpdate}
				currentAppPlan={currentAppPlan}
				isTarget={isTarget}
			/>
		);
	}

	if (message.mode === "LINE/STICKER") {
		return (
			<Sticker
				message={message}
				copyComponentNode={copyComponentNode}
				removeComponentNode={removeComponentNode}
				copyToClipBoard={copyToClipBoard}
				onSelectNode={onSelectNode}
				detail={detail}
				onHandleUpdate={onHandleUpdate}
				currentAppPlan={currentAppPlan}
				isTarget={isTarget}
			/>
		);
	}

	if (message.mode === "ORDERS-TEMPLATE") {
		return (
			<OrdersTemplate
				message={message}
				copyComponentNode={copyComponentNode}
				removeComponentNode={removeComponentNode}
				copyToClipBoard={copyToClipBoard}
				onSelectNode={onSelectNode}
				detail={detail}
				onHandleUpdate={onHandleUpdate}
				currentAppPlan={currentAppPlan}
				isTarget={isTarget}
			/>
		);
	}

	if (message.mode === "BUSINESS-HOURS") {
		return (
			<BusinessHours
				message={message}
				copyComponentNode={copyComponentNode}
				removeComponentNode={removeComponentNode}
				copyToClipBoard={copyToClipBoard}
				onSelectNode={onSelectNode}
				detail={detail}
				onHandleUpdate={onHandleUpdate}
				currentAppPlan={currentAppPlan}
				isTarget={isTarget}
			/>
		);
	}

	if (message.mode === nodeModes.DIAGRAM_GROUP) {
		return (
			<DiagramGroup
				message={message}
				copyComponentNode={copyComponentNode}
				removeComponentNode={removeComponentNode}
				copyToClipBoard={copyToClipBoard}
				onSelectNode={onSelectNode}
				detail={detail}
				onHandleUpdate={onHandleUpdate}
				currentAppPlan={currentAppPlan}
				isTarget={isTarget}
				id={id}
			/>
		);
	}

	if (
		advancedMessages["ESSENTIALS"].messages[message.mode] ||
		advancedMessages["QUESTIONS"].messages[message.mode]
	) {
		return (
			<QuestionNode
				message={message}
				copyComponentNode={copyComponentNode}
				removeComponentNode={removeComponentNode}
				copyToClipBoard={copyToClipBoard}
				onSelectNode={onSelectNode}
				detail={detail}
				onHandleUpdate={onHandleUpdate}
				currentAppPlan={currentAppPlan}
				isTarget={isTarget}
			/>
		);
	}

	return (
		<PipelineNode
			message={message}
			copyComponentNode={copyComponentNode}
			removeComponentNode={removeComponentNode}
			copyToClipBoard={copyToClipBoard}
			onSelectNode={onSelectNode}
			detail={detail}
			onHandleUpdate={onHandleUpdate}
			currentAppPlan={currentAppPlan}
			isTarget={isTarget}
		/>
	);
}

const mapStateToProps = (state) => ({
	selectedApp: state.system.selectedApp,
	currentAppPlan: state.subscription.currentAppPlan,
	isHasIntro: state.messageConfig.isHasIntro,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	onSelectNode: (message) => {
		dispatch(actions.onSelectNode(message));
	},
	isHasIntroBot: (status) => {
		dispatch(actions.isHasIntro(status));
	},
	removeComponentNode: (id) => {
		useStoreDiagram.getState().removeNode(id);
		dispatch(actions.onSaveImageSizeError(true));
	},
	copyComponentNode: (id) => {
		useStoreDiagram.getState().copyNode(id);
	},
	createNodeByMessageSelection: (mode, position) => {
		useStoreDiagram.getState().createNodeByMessageSelection(mode, position);
	},
});

const Container = connect(mapStateToProps, mapDispatchToProps)(ComponentNode);

export default memo(Container);
