import "./i18n";
import "./styles/index.scss";
import "./styles/shards-dashboards.1.1.0.scss";
import * as actions from "./redux/actions";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { ReactFlowProvider } from "@xyflow/react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux";

function handleChange() {
	const currentValue = store.getState();
	window.store = currentValue;
}

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
	console.log = () => { };
	console.error = () => { };
	console.debug = () => { };
}

store.subscribe(handleChange);

// call first action that should do
store.dispatch(actions.startApp());

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<ReactFlowProvider>
			<Provider store={store}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</Provider>
		</ReactFlowProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
