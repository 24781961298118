import zukeeper from "zukeeper";
import { create } from "zustand";
import { addEdge } from "./edge/addEdge";
import { addEdgeForceLayout } from "./edge/addEdgeForceLayout";
import { endDragEdge } from "./edge/endDragEdge";
import { removeEdgeByNodeId } from "./edge/removeEdgeByNodeId";
import { startDragEdge } from "./edge/startDragEdge";
import { addHandles } from "./handle/addHandles";
import { removeHandles } from "./handle/removeHandles";
import { resetHandles } from "./handle/resetHandles";
import { addNewNode } from "./node/addNewNode";
import { addNewNodeInterface } from "./node/addNewNodeInterface";
import { copyNode } from "./node/copyNode";
import { createDraftForceLayoutNode } from "./node/createDraftForceLayoutNode";
import { createDraftForceLayoutNodeChild } from "./node/createDraftForceLayoutNodeChild";
import { createDraftNode } from "./node/createDraftNode";
import { createNodeByDragAndDrop } from "./node/createNodeByDragAndDrop";
import { createNodeByMessageSelection } from "./node/createNodeByMessageSelection";
import { pasteNode } from "./node/pasteNode";
import { removeAllNodeEdge } from "./node/removeAllNodeEdge";
import { removeNode } from "./node/removeNode";
import { removeNodes } from "./node/removeNodes";
import { resetSelectedNode } from "./node/resetSelectedNode";
import { selectedNode } from "./node/selectedNode";
import { unSelectedNode } from "./node/unSelectedNode";

const storeDiagram = (set, get) => ({
    addEdge: (edges) => {
        addEdge(set, get, edges);
    },
    removeEdgeByNodeId: (id) => {
        removeEdgeByNodeId(set, get, id);
    },
    addHandles: (id, handleIds = []) => {
        addHandles(set, get, id, handleIds);
    },
    removeHandles: (id, handleIds = []) => {
        removeHandles(set, get, id, handleIds);
    },
    resetHandles: (id, handleIds = []) => {
        resetHandles(set, get, id, handleIds);
    },
    addNewNode: (mode, position) => {
        addNewNode(set, get, mode, position);
    },
    addNewNodeInterface: (messages, shouldCreateLink = false) => {
        addNewNodeInterface(set, get, messages, shouldCreateLink);
    },
    createNodeByDragAndDrop: (mode, position) => {
        createNodeByDragAndDrop(set, get, mode, position);
    },
    createDraftNode: (position) => {
        createDraftNode(set, get, position);
    },
    createNodeByMessageSelection: (mode, position, forceLayout = false) => {
        createNodeByMessageSelection(set, get, mode, position, forceLayout);
    },
    copyNode: (id) => {
        copyNode(set, get, id);
    },
    pasteNode: (nodes, isMousePositionCenter) => {
        pasteNode(set, get, nodes, isMousePositionCenter);
    },
    removeNode: (id) => {
        removeNode(set, get, id);
    },
    removeAllNodeEdge: () => {
        removeAllNodeEdge(set, get);
    },
    isDragingLink: false,
    draftSource: {},
    draftTarget: {},
    startDragEdge: () => {
        startDragEdge(set, get);
    },
    endDragEdge: (
        nodeIdSource = null,
        handleIdSource = null,
        nodeIdTarget = null,
        handleIdTarget = null
    ) => {
        endDragEdge(
            set,
            get,
            nodeIdSource,
            handleIdSource,
            nodeIdTarget,
            handleIdTarget
        );
    },
    selectedNodes: {},
    selectedNode: (nodes, userID) => {
        selectedNode(set, get, nodes, userID);
    },
    unSelectedNode: (nodes, userID) => {
        unSelectedNode(set, get, nodes, userID);
    },
    resetSelectedNode: (userID) => {
        resetSelectedNode(set, get, userID);
    },
    createDraftForceLayoutNode: (position) => {
        createDraftForceLayoutNode(set, get, position);
    },
    createDraftForceLayoutNodeChild: (id, category, position) => {
        createDraftForceLayoutNodeChild(set, get, id, category, position);
    },
    addEdgeForceLayout: (edges) => {
        addEdgeForceLayout(set, get, edges);
    },
    removeNodes: (ids) => {
        removeNodes(set, get, ids);
    },
});

export const useStoreDiagram = create(zukeeper(storeDiagram));

window.storeDiagram = useStoreDiagram;
