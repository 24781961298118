import generateID from "../../../../utils/generateID";
import { useStoreDiagram } from "..";
import { nodeProps } from "../../config";
import { getMessageConfigDiagram } from "../global/diagram";

export const copyNode = (set, get, id) => {
    try {
        const messages = getMessageConfigDiagram();
        const newId = `ms_${generateID()}`;

        const newMessage = {
            ...messages[id],
            ...nodeProps,
            id: newId,
            position: {
                x: window.mousePositionCenterDiagram.current.x,
                y: window.mousePositionCenterDiagram.current.y,
            },
            trigger: null,
        };

        useStoreDiagram.getState().addNewNodeInterface([newMessage]);
    } catch (err) {
        console.error("[copyNode] ", err);
    }
};
